import React, { useState, useEffect } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import '../../style/Payment.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function StripePayment({ open, closeModal, PaymentIntendResponse }) {

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            const result = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: "https://thecaptaintaxis.com.au/book_taxi",
                },
            });


            if (result?.error) {
                toast.error(result?.error?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            } else {
                toast.success('Payment Successful!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                closeModal(true)
            }
        } catch (error) {
            console.log(error)
        }
    };

    const handleClose = () => {
        closeModal(false)
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit}>
                        <PaymentElement />
                        <div style={{ width: '340px', display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <button className='payNow-Button'>Add Card</button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    )
}