import React, { useEffect, useState, useRef } from "react";
import Fade from "react-reveal/Fade";
import { FaLocationCrosshairs, FaLocationDot, FaLocationPinLock, FaTaxi } from "react-icons/fa6";
import {
  useJsApiLoader,

} from "@react-google-maps/api";
import { Link } from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCarContext } from "../CarContext";
import { getCurrentTime, libraries } from "../BookTaxi/BookTap";
import { BiSolidTaxi } from "react-icons/bi";
import { Availablebox } from "./Availablebox";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { MdMyLocation } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";

//const center = { lat: -25.2744, lng: 133.7751 };
const center = { lat: -33.8688, lng: 151.2093 };

export const TopBox2 = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBnAvn0DGsT--D0xLW6l5JQD6Razy9gnvM",
    libraries: libraries,
  });

  const {
    setDestination_Location,
    setPickup_Location,

    Pickup_Location,
    destination_Location,
    setTotalFare,
    Start_Latitude,
    setStart_Latitude,
    Start_Longitude,
    setStart_Longitude,
    Destination_Latitude,
    setDestination_Latitude,
    Destination_Longitude,
    setDestination_Longitude,

    setTravelling_Date,

    setTravelling_Time,

    setDirectionsResponse,
    Taxi_Type,
    setTaxi_Type,
    sethomeRoute
  } = useCarContext();

  const [enterKeyFlag, setEnterFlag] = useState(false);

  const [carPrice, setCarPrice] = useState(null)

  const navigate = useNavigate();
  const originRef = useRef(null);
  const destiantionRef = useRef(null);

  const [loadPrice, setLoadPrice] = useState(false)

  const [locationError, setLocationError] = useState('')

  // Function to calculate the route and fetch distance and duration
  async function calculateAndFetchRoute(origin, destination) {
    if (!origin || !destination) {
      return;
    }

    try {
      // eslint-disable-next-line no-undef
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });

      setDirectionsResponse(results);

      setPickup_Location(originRef.current.value);
      setDestination_Location(destiantionRef.current.value);
      // Extract and set the latitude and longitude of pickup and destination locations
      setStart_Latitude(results.routes[0].legs[0].start_location.lat());
      setStart_Longitude(results.routes[0].legs[0].start_location.lng());
      setDestination_Latitude(results.routes[0].legs[0].end_location.lat());
      setDestination_Longitude(results.routes[0].legs[0].end_location.lng());
      sethomeRoute(true)
    } catch (error) {
      console.error(error);
    }
  }

  // Listen for changes in the Autocomplete input fields and calculate route when selected
  useEffect(() => {
    try {
      const originInput = originRef.current;
      const destinationInput = destiantionRef.current;

      // Define bounds for New South Wales
      const nswBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(-37.505, 140.9993), // Southernmost point of NSW
        new window.google.maps.LatLng(-28.157, 153.638) // Northernmost point of NSW
      );

      const originAutocomplete = new window.google.maps.places.Autocomplete(
        originInput,
        {
          // componentRestrictions: { country: "au" }, // Restrict to Australia
          bounds: nswBounds, // Set bounds to New South Wales
          strictBounds: true,
        }
      );
      originAutocomplete.setFields(["place_id", "name"]);

      const destinationAutocomplete = new window.google.maps.places.Autocomplete(
        destinationInput,
        {
          // componentRestrictions: { country: "au" }, // Restrict to Australia
          bounds: nswBounds, // Set bounds to New South Wales
          strictBounds: true,
        }
      );
      destinationAutocomplete.setFields(["place_id", "name"]);

      originAutocomplete.addListener("place_changed", () => {
        const place = originAutocomplete.getPlace();
        if (!place.place_id) {
          return;
        }
        const origin = place.name;
        setPickup_Location(place?.name)
        const destination = destiantionRef.current.value;
        calculateAndFetchRoute(origin, destination);
      });

      destinationAutocomplete.addListener("place_changed", () => {
        const place = destinationAutocomplete.getPlace();
        if (!place.place_id) {
          return;
        }
        const destination = place.name;
        setDestination_Location(place?.name)
        const origin = originRef.current.value;
        calculateAndFetchRoute(origin, destination);
      });
    } catch (error) {
      console.log(error)
    }
  }, [originRef?.current?.value, destiantionRef?.current?.value]); // Moved the useEffect outside of any conditionals

  useEffect(() => {
    clearForm()
  }, [])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {

      setEnterFlag(true)
    }
  };

  const getFare = async (event, navi) => {
    event && event.preventDefault();
    if (
      Start_Latitude ||
      Start_Longitude ||
      Destination_Latitude ||
      Destination_Longitude
    ) {
      try {
        let { date, time } = getCurrentTime()
        setLoadPrice(true)

        const response = await axios.post(
          "https://taxi2.thecaptaintaxis.com.au/CostCalculationsAPI/",
          {
            Start_Latitude,
            Start_Longitude,
            Pickup_Location,
            Destination_Latitude,
            Destination_Longitude,
            Taxi_Type,
            Fixed_Price: 'True',
            Book_For_Later: 'False',
            Travelling_Date: date,
            Travelling_Time: time,
          }
        )

        if (response.status === 200) {
          if (navi) {
            setCarPrice(null)
            setTotalFare(response.data);

            navigate("/book_taxi", {
              state: {
                Pickup_Location,
                Destination_Latitude,
                Destination_Longitude,
                Taxi_Type,
                routeFlag: "home",
                Fixed_Price: "True"
              },
            });
            return;
          }

          setCarPrice([{ ...response.data.Maxi, title: 'Maxi' }, { ...response.data.Sedan, title: 'Sedan' }])
          setLoadPrice(false)
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      if (!enterKeyFlag) {
        console.log(event?.key)
        // navigate("/book_taxi")
      }
    }
  };

  useEffect(() => {
    getFare()
  }, [Pickup_Location, Start_Latitude, Start_Longitude, Destination_Latitude, Destination_Longitude])

  const handleLocationValue = (e, name) => {
    e.preventDefault()
    let value = e.target.value
    if (name === 'pickup') {
      setPickup_Location(value)
    } else {
      setDestination_Location(value)
    }
  }

  const clearForm = () => {
    setPickup_Location("")
    setDestination_Location("")
    setStart_Latitude("")
    setStart_Longitude("")
    setDestination_Latitude("")
    setDestination_Longitude("")
    setTaxi_Type("Maxi")
    setTravelling_Date("")
    setTravelling_Time("")
    setCarPrice(null)
  }

  const handleCurrentLocationClick = () => {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      const latitude = pos.coords.latitude;
      const longitude = pos.coords.longitude;

      try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBnAvn0DGsT--D0xLW6l5JQD6Razy9gnvM`);


        if (response.data.results.length > 0) {
          const userLocation = response?.data?.results[0]?.formatted_address;

          if (
            userLocation.toLowerCase().includes("nsw") ||
            userLocation.toLowerCase().includes("sydney") ||
            userLocation.toLowerCase().includes("new south wales") ||
            userLocation.toLowerCase().includes("australia")
          ) {
            setPickup_Location(userLocation)
            setLocationError('')
          } else {
            toast.error('Bookings only in Sydney, Australia.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLocationError('Booking are only available in Sydney, Australia.')
            setPickup_Location('')
          }
        } else {
          toast.error('No address found.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.error("No address found for the provided coordinates.");
          setLocationError('Please enter address manually.')
        }
      } catch (error) {
        console.error("Error fetching location:", error);
        setLocationError('Error fetching location. Please try again later.');
      }
    })
  }

  if (!isLoaded) {
    return <p>loading....</p>;
  }

  return (
    <div>
      <div className="topBoxImage">
        <div className="topBoxBlur w-100 h-100">
          <ToastContainer />
          <Fade>
            <div className="col-md-4 topBoxForm">
              <form>
                <h1 className="text-start text-white" style={{ letterSpacing: '0.5rem', fontSize: '45px', fontWeight: '900' }}>Book A Taxi</h1>

                {/* FIRST INPUT */}
                <div className="col-md-12" >
                  {/* <Autocomplete> */}
                  <div className="input-group mt-4">
                    <span className="input-group-text" id="basic-addon1">
                      <FaLocationDot />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Pickup Location"
                      ref={originRef}
                      value={Pickup_Location}
                      onChange={(e) => handleLocationValue(e, 'pickup')}
                      onKeyDown={handleKeyPress}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ color: "white" }}
                    />
                    <span className="input-group-text" onClick={handleCurrentLocationClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <MyLocationIcon color="white" style={{ background: '' }} fontSize="small" />
                    </span>
                  </div>
                  {/* </Autocomplete> */}
                </div>
                <div className="loca-line"></div>

                {/* SECOND INPUT */}
                <div className="col-md-12">
                  {/* <Autocomplete> */}
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <FaLocationPinLock />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Drop Off location"
                      ref={destiantionRef}
                      value={destination_Location}
                      onChange={(e) => handleLocationValue(e, 'dropoff')}
                      onKeyDown={handleKeyPress}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ color: "white" }}
                    />
                  </div>
                  {/* </Autocomplete> */}
                </div>

                {loadPrice && <div className="text-start text-white">Loading...</div>}

                {/* CAR PRICES */}
                {carPrice &&
                  <div className="mt-2">
                    <div className="d-flex gap-3 w-100 align-items-center justify-content-start rounded flex-wrap mb-2" style={{ width: 'fit-content', background: '#c0c0c099' }}>
                      <img src={`/assets/SEDAN01.webp`} alt="sedan-price" width={65} />
                      <h5 className="text-dark m-0">Sedan: <strong> ${Math.round(carPrice[1]?.Estimate)}</strong></h5>
                    </div>
                    <div className="d-flex gap-3 w-100 align-items-center justify-content-start rounded flex-wrap" style={{ width: 'fit-content', background: '#c0c0c099' }}>
                      <img src={`/assets/MAXI01.webp`} alt="maxi-price" width={65} />
                      <h5 className="text-dark m-0">Maxi: <strong> ${Math.round(carPrice[0]?.Estimate)}</strong></h5>
                    </div>
                  </div>
                }

                {/* BUTTON */}
                <div className="col-md-12 mt-4 text-start">
                  <button className="booknowbtn" onClick={(e) => getFare(e, true)}>
                    <BiSolidTaxi className="BiSolidTaxiFont" />
                    &nbsp;BOOK A Ride
                  </button>
                </div>
              </form>
            </div>

            {/* <div className="col-md-6">
              <img src="assets/sedan1.png" alt="sedan picture" />
            </div> */}
          </Fade>
        </div>
      </div>
    </div>
  );
};


// {/* <div className="container" style={{ visibility: 'visible' }}>
//             <div className="row topboxClass" >
//               {/* {carPrice ?
//                 <div className="mt-5" style={{ width: '80%', margin: '20px auto' }}>
//                   <h1 className="text-white mx-auto" style={{ width: 'fit-content', borderBottom: '3px solid gray' }}>Car Prices</h1>

//                   <div className="d-flex gap-4 gap-sm-2 justify-content-center align-items-center my-4 flex-wrap w-100">
//                     {carPrice.map((carData) => (
//                       <Fade>
//                         <div className='col-12 col-sm-6 col-md-4'>
//                           {console.log(carData)}
//                           <div className='card1 p-3 availableBoxShadows'>
//                             <div className='img1'><img src={`/assets/${carData.title}.jpg`} alt='connect' className='availableBoxFadCars' /></div>
//                             <h5 className="mt-3 car-price">Estimated Price: <strong>{Math.round(carData.Estimate)}$</strong></h5>
//                             <div className='main-text1 mt-3'>
//                               <Link to="/book_taxi"><button className='aboutmorebtn1 w-100'>Book Taxi now <BsArrowRight /></button></Link>
//                             </div>
//                           </div>
//                         </div>
//                       </Fade>
//                     ))}
//                   </div>
//                 </div>
//                 :
//                 <> */}
//               <Fade>
//                 <div className="col-md-6 mt-2 mb-2 p-md-3 text-align-left"  >
//                   <form className="px-2 topBoxFields" >

//                     {/* FIRST INPUT */}
//                     <div className="row" >
//                       <div className="col-md-12" >
//                         {/* <Autocomplete> */}
//                         <div
//                           className="input-group mt-4 mb-3 topBoxfieldsDiv"
//                         >
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder="Pickup Location"
//                             ref={originRef}
//                             value={Pickup_Location}
//                             onChange={(e) => handleLocationValue(e, 'pickup')}
//                             onKeyDown={handleKeyPress}
//                             aria-label="Username"
//                             aria-describedby="basic-addon1"
//                             style={{ color: "white" }}
//                           />
//                           <span className="input-group-text" id="basic-addon1">
//                             <FaLocationDot />
//                           </span>
//                         </div>
//                         {/* </Autocomplete> */}
//                       </div>
//                     </div>

//                     {/* SECOND INPUT */}
//                     <div className="row">
//                       <div className="col-md-12">
//                         {/* <Autocomplete> */}
//                         <div
//                           className="input-group mt-4 mb-3 topBoxfieldsDiv"
//                         >
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Drop Off location"
//                             ref={destiantionRef}
//                             value={destination_Location}
//                             onChange={(e) => handleLocationValue(e, 'dropoff')}
//                             onKeyDown={handleKeyPress}
//                             aria-label="Username"
//                             aria-describedby="basic-addon1"
//                             style={{ color: "white" }}
//                           />
//                           <span className="input-group-text" id="basic-addon1" style={{ color: "white" }}>
//                             <FaLocationPinLock />
//                           </span>
//                         </div>
//                         {/* </Autocomplete> */}
//                       </div>
//                     </div>

//                     {/* CAR PRICES */}
//                     {carPrice &&
//                       <div className="mt-2">
//                         <div className="d-flex gap-3 bg-light align-items-center justify-content-center flex-wrap border p-2 mb-2 border-primary rounded" style={{ width: 'fit-content' }}>
//                           <img src={`/assets/sedan.jpg`} alt="" width={50} />
//                           <h5 className="text-dark m-0">Sedan: <strong> {Math.round(carPrice[0].Estimate)}$</strong></h5>
//                         </div>
//                         <div className="d-flex gap-3 bg-light align-items-center justify-content-center flex-wrap border p-2 border-primary rounded" style={{ width: 'fit-content' }}>
//                           <img src={`/assets/Maxi.jpg`} alt="" width={50} />
//                           <h5 className="text-dark m-0">Maxi: <strong> {Math.round(carPrice[1].Estimate)}$</strong></h5>
//                         </div>
//                       </div>
//                     }

//                     {/* BUTTON */}
//                     <div className="row">
//                       <div className="col-md-6 mt-3">
//                         <button className="booknowbtn" onClick={(e) => getFare(e, true)}>
//                           <BiSolidTaxi className="BiSolidTaxiFont" />
//                           &nbsp;BOOK A Ride
//                         </button>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </Fade>

//               {/* <Fade right>
//                 <div className="col-md-6 d-flex align-items-center justify-content-center topBoxCar"
//                 >
//                   <img src="/assets/sedan1.png" className="topBoxSedanCar" alt="cartaxi" />
//                 </div>;
//               </Fade> */}
//               {/* </>
//               } */}
//             </div>
//           </div> */}