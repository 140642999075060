import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './Components/Home/Home';
import About from './Components/about/About';
import ScrollToTop from './Components/ScrollToTop';
import Policy from './Components/policy/Policy';
import Contact from './Components/contact/Contact';
import JoinasaDriver from './Components/joinAsaDriver/JoinasaDriver';
import BookTaxi from './Components/BookTaxi/BookTaxi';
import Services from './Components/Service/Services';
import Blog from './Components/Home/Blog';
import AdditionalLinks from './Components/additionalLinks/additionalLinks';
import VehiclesPage from './Components/vehicles/vehicles';
import Terms from './Components/terms/Terms';
import QRPage from './Components/qr/qr';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/vehicles" element={<VehiclesPage />} />
        <Route path="/policy" element={<Policy />} />
        {/* <Route path="/terms_conditions" element={<Terms />} /> */}
        <Route path="/contact_us" element={<Contact />} />
        <Route path="/join_as_a_driver" element={<JoinasaDriver />} />
        <Route path="/book_taxi" element={<BookTaxi />} />
        <Route path="/services" element={<Services />} />
        <Route path="/qr" element={<QRPage />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/additional_links" element={<AdditionalLinks />} />
      </Routes>
    </div>
  );
}

export default App;
