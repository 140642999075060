import React from 'react';
import { BiSolidPhoneCall } from 'react-icons/bi';


export const CallToAction = () => {
  return (
    <div className="container mb-5">
      <div className='row calltoActionBgColor'>
        <div className='col-lg-8 d-flex z align-items-center calltoActionimgdiv' style={{ clipPath: window.innerWidth <= 990 ? 'none' : 'polygon(0 0, 95% 0, 100% 97%, 100% 100%, 0 100%)' }}>
          <img src="/assets/Logo1.png" alt="logo" className="cta-logo" style={{ objectFit: 'cover' }} width={200} height={100} />
          <span className='calltoActionimgTextdiv'> We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master new technologies.</span>
        </div>
        <div className='col-lg-4 d-flex align-items-center calltoActionRightDiv' style={{ justifyContent: window.innerWidth <= 800 ? 'center' : 'start' }}>
          <div className='d-flex'>
            <BiSolidPhoneCall className='calltoActionRightDivIcon' />
            <div className='contactContents calltoActionRightDivIconMainDiv'>
              <span>Call For Taxi</span><br />
              <span className='calltoActionRightDivIconMainDivPhone'>0420 966 387</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
