import React from 'react';
import { FaLocationDot, FaLocationPinLock, FaTaxi } from 'react-icons/fa';
import { BsApple, BsGooglePlay } from 'react-icons/bs';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api';


const center = { lat: 48.8584, lng: 2.2945 };

export const BookingFormBox = () => {
  return (
    <div className="videobox mt-5">
      <div className="videobox-image">
        <div style={{ position: 'relative' }}>
          <div className="overlay"></div>
          <img src="/assets/Bookingformbg.jpg" alt="Book a ride" />
        </div>
        <div>
          <h1 className="welcome-taxi bookformBoxheadingText p-2">Welcome to the captain taxis</h1>

          <div className='bookFormBoxbuttonDiv flex-wrap'>
            <button className="googlebtn align-items-center gap-2 mt-md-4 mb--">
              <div className="col-2">
                <img
                  src="/assets/google.png"
                  alt="googleplay"
                  id="googleplaystor"
                />
              </div>
              {/* <div className="col-10 text-align-left"> */}
              <div className="col-10 appstoreBookingformDiv">
                {/* <span className='bookingFormGoogleplay'>Get it on</span> */}
                <span className='appstoreBookingformDivspan'>Get it on</span>
                <br /> Google Play
              </div>
            </button>

            <button
              className="downloadbtn align-items-center gap-2 mt-md-4 mb-0 text-align-left"
            >
              <div className="col-2">
                <BsApple className="appleicon" />
              </div>
              <div className="col-10 appstoreBookingformDiv" >
                <span className='appstoreBookingformDivspan'>Download on the</span>
                <br /> App Store
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
