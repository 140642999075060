import { useState } from "react";
import { HeaderMenu } from "../Home/HeaderMenu"
import { Footer } from "../footer/Footer"
import '../../style/about.css'
import { Modal } from "@mui/material"
import { ParallaxDiv } from "../parallaxDiv/parallaxDiv";
import { ReviewBox } from "../Home/ReviewBox";
import { Features } from "../Home/Features";
import Box from '@mui/material/Box';
import { ImCross } from "react-icons/im";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: '#c0c0c0',
    border: 'none',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
};

const About = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <HeaderMenu />

            <ParallaxDiv image="assets/ABOUT-US.jpg" title="The Captain Taxis Story - Pioneering Excellence in Transportation Services" content="A commitment to reliability, safety, and exceptional service, weaving together your seamless travel experience." attach="fixed" position="left" enable={true} />

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'sticky', top: '0', textAlign: 'end' }}>
                            <ImCross size={25} style={{ cursor: 'pointer' }} color="indigo" onClick={handleClose} />
                        </div>
                        <h1 className="mb-4" style={{ color: 'indigo', fontWeight: '700' }}>About The Captain Taxis.</h1>
                        <h5>
                            Welcome to our world, where the spirit of passion, dedication, and genuine care defines us - I am Farid, known as "The Captain." As a father of four and an experienced chef with over 25 years in London's prestigious restaurants, my journey led me to Australia 22 years ago. Eager to escape the pain of standing long hours in the kitchen, fate guided me to a new adventure - taxi driving.
                            <br />
                            <br />
                            In the face of rideshare's emergence in 2017, the taxi industry experienced significant challenges, leaving many drivers uncertain about their livelihoods and taxi plate owners grappling with plummeting values. However, I've learned resilience as a seasoned chef, where improvisation and adaptability were second nature during trying times. Throughout my six years in the taxi industry, I've witnessed the ebb and flow of change, but one thing remains constant - my unwavering commitment to supporting fellow drivers. Countless old-timers have left or moved on, but a new generation of cabbies embraces this legacy, keeping the industry alive.
                            <br />
                            <br />
                            This website and the application we're building are mere reflections of the dedication we've put into this industry over the years. I stand as a representative of numerous hardworking and professional drivers who take immense pride in serving you with excellence. But don't just take my word for it - experience it firsthand. Give us a chance to prove that we offer the best service of your life. Our government-regulated metered rates guarantee fairness, while safety and security are our unwavering commitments as your journey from home to work or any planned destination.
                            <br />
                            <br />
                            I invite you to embark on this fresh experience with me as your personal guide. Each ride will be personally monitored, and your feedback, critiques, and honest reviews are not only welcome but cherished. Thank you for sparing a moment from your precious time to read these lines. As "The Captain Farid," I pledge to uphold our values and deliver a service that surpasses expectations.
                            <br />
                            <br />
                            Let's set sail together towards a new chapter of exceptional taxi experiences.
                        </h5>
                    </div>
                </Box>
            </Modal >

            {/* <div className="mt-5 container pt-3 desktopdisplay" style={{ padding: 0, height: 'auto', background: '#c0c0c0' }}> */}
            {/* <div className="mt-5 container desktopdisplay" style={{ padding: 0, height: 'auto' }}>
                <div className="about-div-con mt-3 about-img">
                    <div>
                        <h1>About The Captain Taxis</h1>
                        <h5>
                            Welcome to our world, where the spirit of passion, dedication, and genuine care defines us - I am Farid, known as "The Captain." As a father of four and an experienced chef with over 25 years in London's prestigious restaurants, my journey led me to Australia 22 years ago. Eager to escape the pain of standing long hours in the kitchen, fate guided me to a new adventure - taxi driving.
                        </h5>
                        <button className="aboutmorebtn1" onClick={handleOpen}>Read More</button>
                    </div>
                </div>
            </div> */}

            <div className="mt-5 container" style={{ padding: 0, height: 'auto' }}>
                <div className="about-div-con">
                    <div>
                        <img src="assets/about.webp" alt="" />
                    </div>
                    <div>
                        <h1>About The Captain Taxis</h1>
                        <h5>
                            Welcome to our world, where the spirit of passion, dedication, and genuine care defines us - I am Farid, known as "The Captain." As a father of four and an experienced chef with over 25 years in London's prestigious restaurants, my journey led me to Australia 22 years ago. Eager to escape the pain of standing long hours in the kitchen, fate guided me to a new adventure - taxi driving.
                        </h5>
                        <button className="aboutmorebtn1" onClick={handleOpen}>Read More</button>
                    </div>
                </div>
            </div>

            <div className="my-5">
                <Features />
            </div>

            <ReviewBox />

            <Footer />
        </>
    )
}

export default About