import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderMenu } from "../Home/HeaderMenu"
import { Footer } from "../footer/Footer"
import { Header } from "../header/Header"
import "../../style/Policy.css"

const Policy = () => {
   const [activeTab, setActiveTab] = useState("Policy");
   const location = useLocation();

   useEffect(() => {
      const params = new URLSearchParams(location.search);
      if (params.get('selected') === 'terms') {
         setActiveTab('Terms');
      }
   }, [location]);

   return (
      <>
         <HeaderMenu />
         <div className="main">
            <div className="container py-5 policyContainerDiv">
               <div>
                  <span
                     className={`h3 m-0 ${activeTab === "Policy" ? "active" : ""}`}
                     onClick={() => setActiveTab("Policy")}
                  >
                     Our Policy
                  </span>
                  <span className="h3"> / </span>
                  <span
                     className={`h3 ${activeTab === "Terms" ? "active" : ""}`}
                     onClick={() => setActiveTab("Terms")}
                  >
                     Terms and Conditions
                  </span>
               </div>

               <div>
                  {/* POLICY */}
                  {activeTab === "Policy" && (
                     <div className="policyContentDiv">
                        <h4 className="text-center text-dark">
                           Use of Information
                           <br />
                           DRIVER -TAXI SERVICE PROVIDER CONSENT
                        </h4>

                        <br />
                        I consent to the private and confidential Information supplied by me, and the data generated from the operational use of programmes by the Captain Taxis to be retained and /or aggregated for the proper purposes of the verification processes as required by Authorised Governmental Agencies in Australia, but limited to Taxi Industry related purposes.

                        <br />
                        <br />
                        I supply and give consent for the use and recording thereof of Personal Images, Driver’s License, Medicare, Australian Criminal Security Checks and Reports, Passport and Driving Records. I consent also to the recording and use of other Records in the case of not being an Australian Citizen.


                        <br />
                        <br />
                        I consent to the Captain Taxis retaining and accessing data for the purpose of confirming that I am an eligible driver under Point to Point Transport Regulations, and to update DVD records. I understand that The Captain Taxis will be able to check whether I have the relevant driver licence to provide passenger services and whether I have any disqualifying offences recorded against my name (criminal charges, serious driving offences and Point to Point safety offences).

                        <br />
                        <br />
                        I consent to all relevant information being supplied to
                        <a href="https://stripe.com/" target="_blank"> <span style={{ color: "#4b0082", marginLeft: "0px" }}> STRIPE.COM </span> </a>
                        for Payment Processing Services subject to the Stripe Connected Account Agreement, collectively the “Stripe Services Agreement.

                        <br />
                        <br />
                        I do not consent to personal information being passed to any other parties for marketing purposes, or for any other use, other than as required by Law in Australia or in any other Countries. The use of Aggregated data by The Captain Taxis is permitted only to the extent that my personal data remains unidentifiable.

                        <br />
                        <br />
                        I herewith confirm that I will supply The Captain Taxis with updates of the status of myself as a Taxi / Driver as may be required by Governmental Regulation, from time to time, and / or as to reflect changes to my current status.

                        <br />
                        <br />
                        I hereby also give consent to the deduction of monies from my share of Taxi Fares to be retained by The Captain Taxi for amounts payable as Government Passenger Services Levy, Booking Fees, Credit Card Transaction Fees, and any charges arising from Fares not processed through The Captain Taxis.

                        <br />
                        <br />
                        I authorise and consent to a percentage of Fares to be paid into The Captain Taxis Account, subject to such nomination and acceptance being recorded from both Driver and The Captain Taxis.

                        <br />
                        <br />
                        I consent to Taxi Fares being paid into an Account nominated by me as a Driver.

                        <br />
                        <br />
                        Consent is Given by Acceptance of this Consent Document by the Driver as part of his Registration with The Captain Taxis.
                     </div>
                  )}

                  {/* TERMS */}
                  {activeTab === "Terms" && (
                     <div className="policyContentDiv">
                        <h4 className="text-center text-dark">
                           The Captain Taxis Terms & Conditions
                        </h4>

                        <br />
                        <div>
                           Thank you for using our Services. These are our relevant general terms and conditions (Terms and Conditions) that apply to your visit to and your use of our Website, Application, Call Centre, and all other related and ancillary services.

                           <br />
                           <br />
                           The Captain Taxis is operating services with the following ABN 50181719370. We manage services, websites, and applications for our passengers/users (Customers) to book a taxi using our websites or mobile applications

                           <br />
                           <br />
                           The Captain Taxis is a Taxi Booking and Payment Service enabling you to book, and pay for, a taxi cab through the use of an application supplied by us and downloaded and installed by you on your single mobile device (smartphone) (Application), via our call centre by calling 0420 966 387 (Call Centre) or via our website at <a className='text-dark' href="https://www.thecaptaintaxis.com.au/"> www.captaintaxis.com.au</a>

                           <br />
                           <br />
                           The Captain Taxis connects you to taxi drivers so that taxi drivers may pick you, or your parcels, up and take you, or your parcels, where you need to go.

                           <br />
                           <br />
                           All services provided to you by means of your use of the Application, Call Centre, and/or Website, as well as all other related and ancillary services such as parcel delivery, text message confirmations, and "contact your driver" are collectively referred to as the Service(s).

                           <br />
                           <br />
                           The Captain Taxis does not provide transportation services. The Captain Taxis facilitates the formation and administration of travel arrangements between you and taxi drivers.

                           <br />
                           <br />
                           By using our Services, you are acknowledging that The Captain Taxis is not providing you with transportation services and agreeing that The Captain Taxis has no responsibility or liability for any transportation services or parcel delivery services provided to you by taxi drivers.

                           <br />
                           <br />
                           Further information about us can be found at <a className='text-dark' href="https://www.thecaptaintaxis.com.au/"> www.captaintaxis.com.au</a>

                           <br />
                           <br />
                           <h5>
                              General:
                           </h5>
                           <span style={{ fontWeight: 'bold' }}>1.1 </span>
                           By registering on our Website and/or Application, and on each occasion that you choose to use our Services, you consent to these Terms and Conditions. Neither the registration nor the use of the complete spectrum of Services shall be possible without explicit consent to these Terms and Conditions.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>1.2 </span>
                           We reserve the right to amend these Terms and Conditions at any time. Following such amendment, the continued use of our Services by you is subject to your consent to such amended terms and conditions.

                           <br />
                           <br />
                           Your continued use of the Services following posting of any changes to this agreement constitutes acceptance of those changes.

                           <br />
                           <br />
                           It is your responsibility to check this agreement periodically for changes. The Captain Taxis may also, in the future, offer new services and/or features through the Services. Such new features and/or services shall be subject to these Terms and Conditions.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>1.3 </span>
                           You will be notified of any changes to these Terms and Conditions via email or the Application.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>1.4 </span>
                           By using our Services, you are entering into an agreement with us to be bound by these Terms and Conditions.

                           <br />
                           <br />
                           In order to be able to first use our Services, you first need to sign up with us.

                           <br />
                           <br />
                           When signing up, you are obliged to provide us with your personal information. Upon successful completion of your signing up with us, we will provide you with a personal account, accessible for you with a password of your choice. You may also be required to provide us with your credit card or Cabcharge account details.

                           <br />
                           <br />
                           <h5>
                              Payment:
                           </h5>
                           <span style={{ fontWeight: 'bold' }}>2.1 </span> The use of our Services is free of charge. We reserve the right to introduce a fee for the use of our Services. If we decide to introduce such a fee, we shall inform you accordingly and allow you to either continue or terminate using our Services.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>2.2 </span>
                           In some cases, we may authorize:

                           <br />
                           <br />
                           (a) a temporary charge on your credit card (or whatever payment method you use) for any amount up to $1 to verify that your card is open and in good standing.
                           This charge will appear as 'pending' and will be automatically removed after verification;

                           <br />
                           <br />
                           (b) a temporary charge on your credit card (or whatever payment method you use) for any amount up to the estimated amount of the fare to verify that your card is open and in good standing.

                           <br />
                           <br />
                           If you have completed the trip, then the temporary charge will be used to pay the fare, but if you cancel the trip, then any pre-authorized amount will be refunded to you.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>2.3 </span>
                           If you choose to pay via the Website, Application, or Call Centre, we shall charge you for the taxi services provided to you by the taxi drivers on behalf of the taxi drivers.

                           <br />
                           <br />
                           You agree that you will pay for all taxi services, and that we may charge your credit card or Cabcharge account as provided by you for the taxi services (including any taxes and late fees, as applicable) that may be accrued by or in connection with your account.

                           <br />
                           <br />
                           You are responsible for the timely payment of all fees and for providing us with a valid credit card or Cabcharge account for payment of all fees at all times. Any payment made is non-refundable.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>2.4 </span>
                           Unless agreed otherwise, charges to you are based on the Government regulated metered fares and any tolls that may apply to your ride.

                           <br />
                           <br />
                           You agree to pay an additional service fee of 5% in the Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Victoria, and Western Australia.

                           <br />
                           <br />
                           We shall also charge you an additional fee if you elect to take up the fixed fare guarantee option when making a booking.

                           <br />
                           <br />
                           These charges will be added to your taxi fare at the end of your trip.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>2.5 </span>
                           If we use a third-party payment processor (the "Payment Processor") to link your credit card account to the Service, this paragraph will apply.

                           <br />
                           <br />
                           The processing of payments or credits, as applicable, in connection with your use of the Service will be subject to the terms, conditions, and privacy policies of the Payment Processor and your credit card issuer in addition to these Terms and Conditions.

                           <br />
                           <br />
                           We are not responsible for any errors by the Payment Processor. In connection with your use of the Services, we will obtain certain transaction details, which we will use solely in accordance with our Privacy Policy.

                           <br />
                           <br />
                           <h5>
                              Conditions of Carriage for Parcel Delivery:
                           </h5>
                           <span style={{ fontWeight: 'bold' }}>3.1 </span>
                           If you choose to use The Captain Taxis Parcel Delivery Service, you:

                           <br />
                           <br />
                           (a) warrant that the goods are not deemed Dangerous Goods in accordance with the Australian Code for the Transport of Dangerous Goods;

                           <br />
                           <br />
                           (b) warrant that the goods do not contain any illicit substances or any substances prohibited by law, including but not limited to drugs;

                           <br />
                           <br />
                           (c) agree that the driver is authorized to deliver the goods at the address nominated by you;

                           <br />
                           <br />
                           (d) agree that if the recipient is not there to accept the goods, the goods will be returned to a The Captain Taxis office;

                           <br />
                           <br />
                           (e) agree that the driver may release the goods to any person who presents him/herself to the driver as the receiver, and the driver shall be conclusively presumed to have delivered the good in accordance with these Terms and Conditions if the driver and/or The Captain Taxis can provide a GPS pin drop or photograph in relation to the delivery of the goods at the nominated address.

                           <br />
                           <br />
                           <h5>
                              Communication Provider Charges:
                           </h5>
                           <span style={{ fontWeight: 'bold' }}>4.1 </span>
                           You understand that the use of our Services may attract data and/or communication carriage charges.

                           <br />
                           <br />
                           <span style={{ fontWeight: 'bold' }}>4.2 </span>
                           We are not responsible for any data or telephone charges incurred by you as a result of you using our Services, including but not limited to mobile phone charges, text messages, and the internet.

                           <br />
                           <br />
                           Links to Third Party Sites
                           5.1 The Services may contain links to other Websites (Linked Sites). The Linked Sites are not under the control of The CaptainTaxis, and The Captain Taxis is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site.

                           <br />
                           <br />
                           The last update was on 28-01-24
                        </div>
                     </div>
                  )}

               </div>
            </div>
         </div>
         <Footer />
      </>
   )
}
export default Policy