import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AiOutlineMail, AiFillTwitterCircle } from 'react-icons/ai';
import { BsApple, BsArrowRight, BsGooglePlay, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { FaLocationDot, FaLocationArrow, FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin, FaTelegram } from "react-icons/fa6";
import { FaFacebookF, FaTelegramPlane, FaYoutubeSquare } from "react-icons/fa";
import { BiSolidPhoneCall } from "react-icons/bi";
import "../../style/footer.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

//  09:43 AM E-mail admin@thecaptaintaxis.com.au

export const Footer = () => {

  return (
    <>
      <footer className='footer-mainbox desktopboxdisplay footerMainDivColor' >
        <div className='px-5 py-3'>
          <div className="row px-4 py-2 justify-content-between gap-5">
            <div className="col footerMainSubDiv">
              <div className='text-align-left'>
                <img src='/assets/Logo1.png' alt='logo.svg' className='footerMainSubDivImg' />
                <div className='text-align-left'>

                  <a href="tel:+0420966387" className='mt-2 footerMainIconsStyle d-flex gap-1 align-items-start'>
                    <BiSolidPhoneCall className='col-1 mt-1 text-start' color='indigo' size={25} />
                    <div className="col-11 text-start ">
                      0420 966 387
                    </div>
                  </a>
                  <p className='footerMainIconsStyle d-flex gap-1 align-items-start mt-2'>
                    <FaLocationDot className='col-1 mt-1 text-start' color='indigo' size={25} />
                    <div className="col-11 text-start">
                      541a Burwood Road, Belmore NSW 2192
                    </div>
                  </p>
                  <a href="mailto:Info@thecaptaintaxis.com.au" className='footerMainIconsStyle d-flex gap-1 align-items-start'>
                    <AiOutlineMail color='indigo' className="col-1 mt-1 text-start" size={25} />
                    <div className="col-11 text-start">
                      Info@thecaptaintaxis.com.au
                    </div>
                  </a>

                </div>
              </div>
            </div>

            <div className='col-5'>
              <div className="footerQuickLinksMain">
                <div style={{ textAlign: "center" }}>
                  <h4 className='mx-4' style={{ fontWeight: '900' }}>Quick Links</h4>
                  <ul className='footer-subpart-ul'>
                    <li><Link to="/" className='footerLinkStyle'>Home</Link></li>
                    {/* <li><Link to="/about" className='footerLinkStyle'>About</Link></li> */}
                    <li><Link to="/join_as_a_driver" className='footerLinkStyle'>Join as a Driver</Link></li>
                    <li><Link to="/vehicles" className='footerLinkStyle'>Vehicle</Link></li>
                    <li><Link to="/services"
                      className='footerLinkStyle'>Services</Link></li>
                  </ul>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h4 className='mx-4' style={{ fontWeight: '900' }}>Support Center</h4>
                  <ul className='footer-subpart-ul'>
                    <li><Link to="/policy" className='footerLinkStyle'>Legal Policy/Terms</Link></li>
                    <li><Link to="/contact_us" className='footerLinkStyle'>Contact Us</Link></li>
                    <li><Link to="/about" className='footerLinkStyle'>About Us</Link></li>
                    <li><Link to="/additional_links" className='footerLinkStyle'>Additonal Links</Link></li>
                  </ul>
                </div>
              </div>
              <Link to="/book_taxi"><button className="aboutmorebtn1">Book a Taxi <BsArrowRight /></button></Link>
            </div>

            <div className="col socialMediaMainDiv" >
              <div className='right-section'>
                <p style={{ color: "rgb(0, 0, 0)", marginTop: "1rem", fontSize: "1.5rem", fontFamily: "Poppins", textAlign: "center", fontWeight: '900' }}>Social media Links</p>

                <div className='d-flex row justify-content-center align-items-center gap-3 flex-wrap'>
                  <button className="googlebtn align-items-center gap-2">
                    <div className="col-2">
                      <img
                        src="/assets/google.png"
                        alt="googleplay"
                        id="googleplaystor"
                      />
                    </div>
                    {/* <div className="col-10 text-align-left"> */}
                    <div className="col-10 appstoreBookingformDiv">
                      {/* <span className='bookingFormGoogleplay'>Get it on</span> */}
                      <span className='appstoreBookingformDivspan'>Get it on</span>
                      <br /> Google Play
                    </div>
                  </button>

                  <button
                    className="downloadbtn align-items-center gap-2 text-align-left"
                  >
                    <div className="col-2">
                      <BsApple className="appleicon" />
                    </div>
                    <div className="col-10 appstoreBookingformDiv" >
                      <span className='appstoreBookingformDivspan'>Download on the</span>
                      <br /> App Store
                    </div>
                  </button>
                </div>

                <ul className='socialmediafooterul footerLoweIconDiv'>
                  <li className='footerLoweIconsli'><NavLink to="https://www.facebook.com/thecaptaintaxis" target="_blank"> <FaFacebook className='facebook' /> </NavLink></li>
                  <li className='footerLoweIconsli'><NavLink to="https://www.instagram.com/thecaptaintaxis/?hl=en" target="_blank"> <FaInstagram className='instagram' /> </NavLink></li>
                  <li className='footerLoweIconsli'><NavLink to="https://www.linkedin.com/in/the-captain-taxis-452473288" target="_blank"> <FaLinkedin className='linkedin' /> </NavLink></li>
                  <li className='footerLoweIconsli'><NavLink to="https://www.youtube.com/@thecaptaintaxis" target="_blank"> <FaYoutube className='youtube' /> </NavLink></li>
                  <li className='footerLoweIconsli'><NavLink to="https://t.me/thecaptaintaxis" target="_blank"> <FaTelegram className='telegram' /> </NavLink></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p className='copyrightpara'>
          Copyright &copy; 2024 <a href='/' className='text-decoration-none'>The Captain Taxis</a> |
          All rights reserved.
        </p>
      </footer >

      <footer className='mobileboxdisplay footer-mainbox py-4' style={{ marginTop: "0rem" }}>
        <div className='container text-white'>

          <div className='row gap-2'>
            <Accordion className='col-12' style={{ background: '#4b0082' }}>
              <AccordionSummary
                className='footerdropdownmenu'
                expandIcon={<KeyboardArrowUpIcon className='footermobileicon' sx={{ color: 'white' }} />}
                aria-controls="quick-links-content"
              >
                <div className='row p-2 align-items-center'>
                  <h3 className="footermobiletitleh1 text-white m-0">Quick Links</h3>
                </div>
              </AccordionSummary>
              <AccordionDetails className='border-top border-info'>
                <ul className='footer-subpart-ul' style={{ color: "white" }}>
                  <li><Link to="/home" className='footerLinkStyle text-white'>Home</Link></li>
                  <li><Link to="/join_as_a_driver" className='footerLinkStyle text-white'>Join as a Driver</Link></li>
                  <li><Link to="/vehicles" className='footerLinkStyle text-white'>Vehicles</Link></li>
                  <li><Link to="/services" className='footerLinkStyle text-white'>Service</Link></li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Accordion className='col-12' style={{ background: '#4b0082' }}>
              <AccordionSummary
                expandIcon={<KeyboardArrowUpIcon className='footermobileicon' sx={{ color: 'white' }} />}
                aria-controls="support-center-content"
              >
                <div className='row p-2 align-items-center'>
                  <h3 className="footermobiletitleh1 text-white m-0">Support Center</h3>
                </div>
              </AccordionSummary>
              <AccordionDetails className='border-top border-info'>
                <ul className='footer-subpart-ul'>
                  <li><Link to="/policy" className='footerLinkStyle text-white'>Legal Policy/Terms</Link></li>
                  <li><Link to="/contact_us" className='footerLinkStyle text-white'>Contact Us</Link></li>
                  <li><Link to="/about" className='footerLinkStyle text-white'>About us</Link></li>
                  <li><Link to="/additional_links" className='footerLinkStyle text-white'>Additional Links</Link></li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Accordion className='col-12' style={{ background: '#4b0082' }}>
              <AccordionSummary
                expandIcon={<KeyboardArrowUpIcon className='footermobileicon' sx={{ color: 'white' }} />}
                aria-controls="newsletter-content"
              >
                <div className='row p-2 align-items-center'>
                  <h3 className="footermobiletitleh1 text-white m-0">Newsletter</h3>
                </div>
              </AccordionSummary>
              <AccordionDetails className='border-top border-info'>
                <ul className='footer-subpart-ul my-4'>
                  <p className='footerLinkStyle text-white'>Subscribe Our Newsletter To Get Latest Update And News</p>
                </ul>
                <input id='input-flied' type='email' name="email" placeholder='Your email' className='w-100 rounded' required />
                <br />
                <Link to="/"><button className="aboutmorebtn mt-2">Subscribe Now <FaLocationArrow className="ms-2" /></button></Link>
              </AccordionDetails>
            </Accordion>
          </div>

          <Link to="/book_taxi"><button className="aboutmorebtn mt-4">Book a Taxi <BsArrowRight /></button></Link>

          <div className='social-media-container'>
            <p><NavLink to="https://www.facebook.com/thecaptaintaxis" target="_blank"> <FaFacebook className='facebook' /> </NavLink></p>
            <p><NavLink to="https://www.instagram.com/thecaptaintaxis/?hl=en" target="_blank"> <FaInstagram className='instagram' /> </NavLink></p>
            <p><NavLink to="https://www.linkedin.com/in/the-captain-taxis-452473288" target="_blank"> <FaLinkedin className='linkedin' /> </NavLink></p>
            <p><NavLink to="https://www.youtube.com/@thecaptaintaxis" target="_blank"> <FaYoutube className='youtube' /> </NavLink></p>
            <p><NavLink to="https://t.me/thecaptaintaxis" target="_blank"> <FaTelegram className='telegram' /> </NavLink></p>
          </div>

          <a href="tel:+0420966387" className='mt-2 footerMainIconsStyle d-flex gap-2 align-items-start'>
            <BiSolidPhoneCall className='col-1 mt-1 text-start' color='indigo' size={25} />
            <div className="col-11 text-start ">
              0420 966 387
            </div>
          </a>
          <p className='footerMainIconsStyle d-flex gap-2 align-items-start mt-2'>
            <FaLocationDot className='col-1 mt-1 text-start' color='indigo' size={25} />
            <div className="col-11 text-start">
              541a Burwood Road, Belmore NSW 2192
            </div>
          </p>
          <a href="mailto:Info@thecaptaintaxis.com.au" className='footerMainIconsStyle d-flex gap-2 flex-wrap align-items-start'>
            <AiOutlineMail color='indigo' className="col-1 mt-1 text-start" size={25} />
            <div className="col-11 text-start">
              Info@thecaptaintaxis.com.au
            </div>
          </a>

          {/* <p className='socialMediaIconDivFas mb-3'>
            <a href="tel:+0420966387"><BiSolidPhoneCall color='indigo' size={25} /> 0420 966 387</a>
          </p>

          <p className='socialMediaIconDivFas'><FaLocationDot color='indigo' size={25} /> 541a Burwood Road, Belmore
            NSW 2192
          </p>

          <p className='socialMediaIconDivFas'>
            <a href="mailto:Info@thecaptaintaxis.com.au"><AiOutlineMail color='indigo' size={25} />Info@thecaptaintaxis.com.au</a>
          </p> */}

          <p className='copyrightpara mt-2'>
            Copyright &copy; 2024 <a href='/' className='text-decoration-none'>The Captain Taxis</a> |
            All rights reserved.
          </p>
        </div>
      </footer>
    </>
  )
}