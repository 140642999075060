import { createContext, useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import reducer from "./reducer/CarReducer";

const CarContext = createContext();

const initialState={
    isError:false,
    CarAvailable:[],
    isCarAvailableLoading:false
}

const API ="https://meeraki.com/api/v2/subcat/unstichedpod";

const CarProvider = ({ children }) => {
     const [totalFare,setTotalFare]=useState();
     const [Start_Latitude,setStart_Latitude]=useState(null)
     const [Start_Longitude,setStart_Longitude]=useState(null)
     const [Destination_Latitude,setDestination_Latitude]=useState(null)
     const [Destination_Longitude,setDestination_Longitude]=useState(null)
     const [Travelling_Date,setTravelling_Date]=useState('')
     const [Travelling_Time,setTravelling_Time]=useState('')
     const [Fixed_Price,setFixed_Price]=useState('False')
     const [Book_For_Later,setBook_For_Later]=useState('True')
     const [Pickup_Location,setPickup_Location]=useState('')
     const [destination_Location,setDestination_Location]=useState('')
     const [directionsResponse,setDirectionsResponse]=useState('')
     const [Taxi_Type, setTaxi_Type] = useState("");
     const [homeRoute, sethomeRoute] = useState("");
    return <CarContext.Provider value={{homeRoute, sethomeRoute,Book_For_Later,setBook_For_Later,totalFare,setTotalFare,Start_Latitude,setStart_Latitude,Start_Longitude,setStart_Longitude,Destination_Latitude,setDestination_Latitude,Destination_Longitude,setDestination_Longitude,Travelling_Date,setTravelling_Date,Travelling_Time,setTravelling_Time,Fixed_Price,setFixed_Price,Pickup_Location,setPickup_Location,destination_Location,setDestination_Location, directionsResponse, setDirectionsResponse,Taxi_Type, setTaxi_Type}}>{children}</CarContext.Provider>;
}

//custom hooks
const useCarContext = () => {
  return useContext(CarContext);
};

export { CarProvider, CarContext, useCarContext };