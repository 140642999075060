import { HeaderMenu } from "./HeaderMenu"
import { Footer } from "../footer/Footer"

const Blog = () => {
    return (
        <>
            <HeaderMenu />
            <div className="main" style={{ height: "31vh" }}>
                <h1 className="title">Coming Soon...</h1>
            </div>
            <Footer />
        </>
    )
}
export default Blog