import { useState } from "react";
import { HeaderMenu } from "../Home/HeaderMenu";
import { Footer } from "../footer/Footer";
import ReCAPTCHA from 'react-google-recaptcha';
import Fade from "react-reveal/Fade";
import { FaLocationDot } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { width } from "@mui/system";
import { MapBox } from "../Home/MapBox";
import { GoogleMap } from "@react-google-maps/api";
import {
  useJsApiLoader
} from "@react-google-maps/api";
import { libraries } from "../BookTaxi/BookTap";
import { ParallaxDiv } from "../parallaxDiv/parallaxDiv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const center = { lat: -33.8688, lng: 151.2093 };
const zoom = 10;

const Contact = () => {
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBnAvn0DGsT--D0xLW6l5JQD6Razy9gnvM",
    libraries: libraries,
  });

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isCaptchaVerified) {
      // Proceed with form submission
      // console.log('Form submitted successfully!');
    } else {
      toast.error("Please Verify Captcha!", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <HeaderMenu />

      <ToastContainer />


      <div className="">
        <ParallaxDiv image="assets/CONTACT_BG.jpeg" title="Let's Connect: Reach Out to The Captain Taxis for Swift and Friendly Assistance!" content="Have a question, need assistance, or ready to book your next ride with Captain Taxis? Our friendly team is here to help!" attach="fixed" enable={true} />

        <div className="container my-5">
          <div className="container text-center">
            <div className="row flex-wrap-reverse">
              <div className="col p-sm-5 pt-5">
                <Fade bottom>
                  <h3 style={{ textAlign: "left", color: "#000", fontWeight: '900' }}>
                    Have Any Questions?
                  </h3>
                  <p style={{ textAlign: "left", color: "#000", fontSize: "1rem" }}>
                    Get in touch to discuss your employee wellbeing needs today.
                    Please give us a call, drop us an email or fill out the
                    contact form.
                  </p>
                  <a href="tel:+0420966387" className='mt-2 footerMainIconsStyle d-flex gap-3 align-items-start'>
                    <BiSolidPhoneCall className='col-1 mt-sm-1 text-start' color='indigo' size={35} />
                    <div className="col-11 text-start">
                      <p style={{ fontSize: 21, fontWeight: '700', margin: 0 }}>PHONE</p>
                      0420 966 387
                    </div>
                  </a>
                  <p className='footerMainIconsStyle d-flex gap-3 align-items-start my-4'>
                    <FaLocationDot className='col-1 mt-sm-1 text-start' color='indigo' size={33} />
                    <div className="col-11 text-start">
                      <p style={{ fontSize: 21, fontWeight: '700', margin: 0 }}>ADDRESS</p>
                      541a Burwood Road, Belmore NSW 2192
                    </div>
                  </p>
                  <a href="mailto:Info@thecaptaintaxis.com.au" className='footerMainIconsStyle d-flex gap-3 align-items-start'>
                    <AiOutlineMail color='indigo' className="col-1 text-start" size={35} />
                    <div className="col-11 text-start">
                      <p style={{ fontSize: 21, fontWeight: '700', margin: 0 }}>EMAIL</p>
                      Info@thecaptaintaxis.com.au
                    </div>
                  </a>
                  {/* <p
                    style={{
                      textAlign: "left",
                      color: "#000",
                      fontSize: "1rem",
                    }}
                  >
                    <BiSolidPhoneCall
                    />
                    0420 966 387
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      color: "#000",
                      fontSize: "1rem",
                    }}
                  >
                    <FaLocationDot
                    />
                    Sydney,NSW,Australia
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      color: "#000",
                      fontSize: "1rem",
                    }}
                  >
                    <AiOutlineMail
                    />
                    admin@thecaptaintaxis.com.au
                  </p> */}
                </Fade>
              </div>

              <div class="col p-5 rounded" style={{ background: "#c0c0c0" }}>
                <h3 style={{ textAlign: "left", color: "#000", fontWeight: '900' }}>
                  Contact Us!
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="row my-3">
                    <div className="col-md-6">
                      <input
                        className="input-filed mb-3"
                        type="text"
                        name="name"
                        placeholder="Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="input-filed"
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-md-6">
                      <input
                        className="input-filed mb-3"
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="input-filed"
                        type="text"
                        name="zip_code"
                        placeholder="ZIP Code"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="row my-3 mx-1">
                    <textarea
                      style={{ border: '2px solid #4b0082' }}
                      placeholder="Message"
                      id="textarea"
                      name="message"
                      autoComplete="off"
                    />
                  </div>
                  <ReCAPTCHA
                    className="recaptcha"
                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    sitekey="6LerB2QpAAAAAPzx4qVWr1q1tkqYFJcpAtnmGvZ0"
                    onChange={handleCaptchaChange}
                  />
                  <div className="text-start mt-4">
                    <button className="aboutmorebtn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </div >
      <Footer />
    </>
  );
};
export default Contact;
