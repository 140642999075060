import { Footer } from "../footer/Footer"
import { TopBox2 } from "./TopBox2";
import { AboutBox } from "./Aboutbox";
import { Servicesbox } from "./Servicesbox";
import { Availablebox } from "./Availablebox";
import { PromoCard } from "./PromoCard";
import { BookingFormBox } from "./BookingFormBox";
import { ChooseUs } from "./ChooseUs";
import { BookCall } from "./BookCall";
import { Features } from "./Features";
import { ReviewBox } from "./ReviewBox";
import { GoogleBox } from "./GoogleBox";
import { MapBox } from "./MapBox";
import { CallToAction } from "./CallToAction";
import { HeaderMenu } from "./HeaderMenu";
import "../../style/home.css";

const Home = () => {
  return (
    <>
      <HeaderMenu />
      <div className="home-content-main">
        <TopBox2 />
        <BookingFormBox />
        <MapBox />
        <CallToAction text="Call For Taxi" number="0420-966-387" />
        <Servicesbox />
        <AboutBox />
      </div>
      <Footer />
    </>
  )
}
export default Home

/*
<AboutBox/>
       <Servicesbox/>
       <Availablebox/>
       <CardRight/>
       <div style={{marginTop:"67rem"}}>
       <PromoCard/>
       </div>
 */