import { FaStar } from "react-icons/fa6";
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
  {
    id: 1,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Jane D.',
    para: '"The Captain Taxis made my airport transfer a breeze! Punctual, courteous, and hassle-free. Highly recommended!"'
  },
  {
    id: 2,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'John M.',
    para: '"Used The Captain Taxis for a wheelchair-accessible ride. Impressed with their commitment to inclusivity. Five stars!"'
  },
  {
    id: 3,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Emma S.',
    para: '"Booked them for a corporate event. Professional service and stylish vehicles. Will be our go-to choice!"'
  },
  {
    id: 4,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Mark L.',
    para: '"Reliable parcel delivery service. My packages reached their destination on time, intact. The Captain Taxis, you have my trust!"'
  },
  {
    id: 5,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Olivia P.',
    para: '"Exceptional NDIS support. They understand the importance of patience and reliability. Grateful for their service."'
  },
  {
    id: 6,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'David W.',
    para: '"Had a fantastic experience with the Events & Weddings service. Classy ride for a special day. Thank you, The Captain Taxis!"'
  },
  {
    id: 7,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Sophie H.',
    para: '"Sydney Taxi Tourist Tour was fantastic! Knowledgeable driver and a comfortable ride. Explored the city like never before."'
  },
  {
    id: 8,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Andrew B.',
    para: '"Using The Captain Taxis for corporate accounts simplified our business travel. Efficient, cost-effective, and top-notch service!"'
  },
  {
    id: 9,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Grace T.',
    para: '"As a frequent traveler, I rely on The Captain Taxis for all my airport transfers. Always on time and professional."'
  },
  {
    id: 10,
    img: '/assets/user.png',
    type: 'Customer',
    user: 'Michael G.',
    para: '"Kudos to The Captain Taxis for their commitment to customer satisfaction. Courteous drivers and a variety of services – a winning combination!"'
  },
];


export const ReviewBox = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <div className='container-fluid Reviewboximage'>
      <div className="blur">
        <div className="container pt-5 pb-5">
          <h3 className="howTitle" style={{ color: '#f5f5f5' }}>What our client <span className="text-white" >Say's</span></h3>
          <div className='row col-11 mx-auto mt-4'>
            <Slider {...settings}>
              {data && data.map((item) => {
                return (
                  <div className='col-md-3 p-4' key={item.id}>
                    <div className='popularCategoriesCard2 pt-4 px-3'>
                      <p className='p-3'>{item.para}</p>
                      <div className='row mx-2 justify-content-start align-items-center flex-wrap'>
                        <div className='col-md-3 col-sm-12 mb-2'>
                          <img src={item.img} alt={item.user} className="reviewBoxItemStyle" />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                          <h6 style={{ margin: 0 }}>{item.user}</h6>
                          <div className="reviewBoxitemTypes">{item.type}</div>
                        </div>
                      </div>
                      <p className="mx-auto reviewBoxStarIcons" ><FaStar color="indigo" /><FaStar color="indigo" /><FaStar color="indigo" /><FaStar color="indigo" /><FaStar color="indigo" /></p>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}