import { Link } from 'react-router-dom'
import '../../style/parallax.css'
import { BsArrowRight } from 'react-icons/bs'

export const EstimateDiv = () => {
    return (
        <div style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('assets/Sydney.jpg')` }} className='parallax-con-estimate'>
            <div>
                <div className='flex-row align-items-center gap-md-2 text-md-left flex-wrap flex-md-nowrap'>
                    <div>
                        <h1>Estimate Your Fare with The Captain Taxis</h1>
                        <p className='my-4'>Take control of your journey costs effortlessly - our fare estimation tool ensures clarity and convenience every step of the way.</p>
                    </div>
                    <div>
                        <Link to="/book_taxi"><button className="aboutmorebtn">Estimate my Fare <BsArrowRight /></button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}