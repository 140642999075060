import { HeaderMenu } from "../Home/HeaderMenu"
import { Servicesbox } from "../Home/Servicesbox"
import { EstimateDiv } from "../estimateFare/estimateFare"
import { Footer } from "../footer/Footer"
import { ParallaxDiv } from "../parallaxDiv/parallaxDiv"

const Services = () => {
    return (
        <>
            <HeaderMenu />
            <ParallaxDiv image="assets/CAR-COMPRESS2.jpg" title="Seamless Rides, Unmatched Services: The Captain Taxis Elevating Your Travel Experience" content="Embark on a journey where excellence meets convenience with The Captain Taxis' diverse and personalized service offerings." attach="fixed" enable={true} />

            <div className="my-5">
                <Servicesbox />
            </div>

            <EstimateDiv />
            <Footer />
        </>
    )
}
export default Services