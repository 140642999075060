import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {
  BiSolidPhoneCall,
  BiMapAlt,
} from "react-icons/bi";
import { HiOutlineViewGrid } from "react-icons/hi";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FcApproval } from "react-icons/fc";
import { useNavigate } from "react-router-dom";


import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { Box, MenuItem, Modal, Select } from "@mui/material";
import { useCarContext } from "../CarContext";
import WarningSnackBar from "../WarningSnackBar/WarningSnackBar";
import CustomLoader from "../Loader/Loader";
import { BiLock } from "react-icons/bi";
import { ViewCarDetails } from "./ViewCarDetails";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePayment from "./StripePayment";
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderMenu } from "../Home/HeaderMenu";
import { MdApproval } from "react-icons/md";

const stripePromise = loadStripe('pk_live_51NpOmkFu8ZiSb27XSBs0Gm7PWzY22Q8egGpSAtLwAOJxJM0X2DAdzn6FjR8Nm83J3G8MUpXoC6PgRBp8Wrx6PKc700aHbqpxFw');

const center = { lat: -33.8688, lng: 151.2093 };

const circularProgessStyle = {
  color: 'indigo',
  position: 'absolute',
  top: '50%',
  left: '-10rem',
  marginTop: '-12px',
  marginLeft: '-12px',
}

export const getCurrentTime = (selecctedDate) => {
  try {
    const currentDate = selecctedDate ? new Date(selecctedDate) : new Date();
    let date = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getDate().toString().padStart(2, '0')}/${currentDate.getFullYear()}`
    let time = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`
    return { date, time }
  } catch (error) {
    console.log('current date time error', error)
  }
}

const emailValidation = (email) => {
  var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (regex.test(email)) {
    return true;
  }
  else {
    return false;
  }
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '100%',
  height: 'auto',
  bgcolor: '#c0c0c0',
  border: 'none',
  boxShadow: 24,
  p: 2.5,
  borderRadius: 3
};

export const libraries = ["places"];

const BookTap = () => {
  const {
    totalFare,
    setTotalFare,
    Fixed_Price,
    setFixed_Price,
    Pickup_Location,
    destination_Location,
    Start_Latitude,
    setStart_Latitude,
    Start_Longitude,
    setStart_Longitude,
    Destination_Latitude,
    setDestination_Latitude,
    Destination_Longitude,
    setDestination_Longitude,
    Travelling_Date,
    setTravelling_Date,
    setPickup_Location,
    setDestination_Location,
    Travelling_Time,
    setTravelling_Time,
    Book_For_Later,
    setBook_For_Later,
    directionsResponse,
    setDirectionsResponse,
    Taxi_Type,
    setTaxi_Type,
    homeRoute, sethomeRoute
  } = useCarContext();

  let options = {
    clientSecret: '',
  };

  const navigate = useNavigate()

  const [tab, setTab] = useState("2");
  const [tab1, setTab1] = useState("tab 1");
  const [Passenger_Name, setPassenger_Name] = useState("");
  const [Contact_Number, setContact_Number] = useState("");
  const [Driver_Instructions, setDriver_Instructions] = useState("");
  const [Payment_Method, setPayment_Method] = useState("");
  const [selectedCar, setSelectedCar] = useState(0);
  const [loadingFlag, setloadingFlag] = useState(false);
  const [viewDetails, setviewDetails] = useState(false);
  const [cardPayment, setCardPayment] = useState(false);
  const [switchBtn, setswitchBtn] = useState(false);
  const [directionFlag, setdirectionFlag] = useState(false);
  const [circularProgressFlag, setcircularProgress] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [validateEmail, setvalidateEmail] = useState(false);
  const [ttssDropdown, setTtssDropdown] = useState({
    value: '', wcFglag: false
  });
  const [stripeOptions, setstripeOptions] = useState({ clientSecret: '' });
  const [PaymentIntendResponse, setPaymentIntend] = useState({});
  const [viewDetailsObj, setviewDetailsObj] = useState({});
  const [warningObj, setwarningObj] = useState({ open: false, message: "", type: "" });

  const [open, setOpen] = useState(false);
  const [locationError, setLocationError] = useState('')

  const [Cars] = useState(
    [
      {
        id: 1,
        type: 'Sedan',
        text: 'Sedan',
        details: '1-4 passengers',
        img: "/assets/SEDAN01.webp"
      },
      {
        id: 2,
        type: 'SUV',
        text: 'SUV',
        details: '5-7 passengers',
        img: "/assets/SUV01.webp"
      },
      {
        id: 3,
        type: 'Maxi',
        text: 'Maxi',
        details: '5-11 passengers',
        img: "/assets/MAXI01.webp"

      },
      {
        id: 4,
        type: 'WC',
        text: 'Wheel Chair',
        details: 'Accessible Taxi',
        img: "/assets/Wheelchair.webp"

      },
      {
        id: 5,
        type: 'Delivery',
        text: 'Delivery',
        details: 'Fits in a car',
        img: "/assets/PARCEL-DELIVERY.webp"

      },

    ]
  )

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    navigate('/')
  };

  let count = 0

  /* STRIPE PAYMENT */
  useEffect(() => {
    (async () => {
      try {
        if (totalFare) {
          if (directionFlag) {
            setswitchBtn(true)
            setdirectionFlag(false)
          } else {
            setswitchBtn(e => !e)
          }
          if (Taxi_Type === 'WC') return
          if (totalFare[Taxi_Type]?.Estimate) {
            let amount = totalFare[Taxi_Type]?.Estimate
            callPaymentIntend(amount)
          } else {
            let data = await callFixedFare()
            let amount = data[Taxi_Type]?.Estimate
            callPaymentIntend(amount)
          }
        }
      } catch (error) {

      }
    })()
  }, [totalFare])

  const callFixedFare = async () => {
    try {
      let { date, time } = getCurrentTime();
      let travelDate = Travelling_Date ? getCurrentTime(Travelling_Date)?.date : date
      let travelTime = Travelling_Time ? `${Travelling_Time}:00` : time
      const response = await axios.post("https://taxi2.thecaptaintaxis.com.au/CostCalculationsAPI/",
        {
          Start_Latitude,
          Start_Longitude,
          Pickup_Location,
          Destination_Latitude,
          Destination_Longitude,
          Taxi_Type: Taxi_Type ? Taxi_Type : 'Maxi',
          Fixed_Price: 'True',
          Book_For_Later: showopen ? 'True' : 'False',
          Travelling_Date: travelDate,
          Travelling_Time: travelTime
        }
      );
      if (response.status === 200) {
        setcircularProgress(false)
        setTaxi_Type('Maxi')
        let data = response?.data
        return data
      }
    } catch (error) {
      setcircularProgress(false)
    }
  }

  const callPaymentIntend = async (value) => {
    const response = await axios.post("https://taxi2.thecaptaintaxis.com.au/StripeAPI/", { amount: value || 0.00 })
    if (response.status === 200) {
      setstripeOptions({ clientSecret: response?.data?.clientSecret })
      setPaymentIntend({ ...response?.data, amount: value })
    }
  }

  const closePaymentModal = async (paid) => {
    setCardPayment(false)
    if (!paid) {
      setPayment_Method("cash")
    } else {
      await PassengerDetails()
    }
  }

  /* STRIPE PAYMENT */
  const [showopen, setShowopen] = useState(false);
  const [cars, setCars] = useState(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBnAvn0DGsT--D0xLW6l5JQD6Razy9gnvM",
    libraries: libraries,
  });

  const [map, setMap] = useState(/** @type google.maps.Map */(null));
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef(null);
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef(null);

  useEffect(() => {
    return () => {
      count++
      if (count === 2) {
        clearForm()
      }
    };
  }, []);

  const clearForm = () => {
    setPickup_Location("")
    setDestination_Location("")
    setStart_Latitude("")
    setStart_Longitude("")
    setDestination_Latitude("")
    setDestination_Longitude("")
    setTaxi_Type("")
    setTravelling_Date("")
    setTravelling_Time("")
    setDirectionsResponse("")
    setTotalFare(null)
    setLocationError("")
    setUserEmail("")
  }

  useEffect(() => {
    try {
      if ((Travelling_Time && Travelling_Date) && !homeRoute) {
        (async () => {
          await getFixedFare()
        })()
      } else {
        sethomeRoute(false)
      }
    } catch (error) {

    }
  }, [Travelling_Time, Travelling_Date])
  // Function to calculate the route and fetch distance and duration

  async function calculateAndFetchRoute(origin, destination) {
    if (!origin || !destination) {
      return;
    }

    try {
      // eslint-disable-next-line no-undef
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });

      setDirectionsResponse(results);
      setDistance(results.routes[0].legs[0].distance.text);
      setDuration(results.routes[0].legs[0].duration.text);
      // Extract and set the latitude and longitude of pickup and destination locations
      setStart_Latitude(results.routes[0].legs[0].start_location.lat());
      setStart_Longitude(results.routes[0].legs[0].start_location.lng());
      setDestination_Latitude(results.routes[0].legs[0].end_location.lat());
      setDestination_Longitude(results.routes[0].legs[0].end_location.lng());
    } catch (error) {
      console.error(error);
    }
  }

  // Listen for changes in the Autocomplete input fields and calculate route when selected
  useEffect(() => {
    try {
      const originInput = originRef.current;
      const destinationInput = destiantionRef.current;

      // Define bounds for New South Wales
      const nswBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(-37.505, 140.9993), // Southernmost point of NSW
        new window.google.maps.LatLng(-28.157, 153.638) // Northernmost point of NSW
      );

      const originAutocomplete = new window.google.maps.places.Autocomplete(
        originInput,
        {
          bounds: nswBounds, // Set bounds to New South Wales
          strictBounds: true,
        }
      );
      originAutocomplete.setFields(["place_id", "name"]);

      const destinationAutocomplete = new window.google.maps.places.Autocomplete(
        destinationInput,
        {
          bounds: nswBounds, // Set bounds to New South Wales
          strictBounds: true,
        }
      );
      destinationAutocomplete.setFields(["place_id", "name"]);

      originAutocomplete.addListener("place_changed", () => {
        const place = originAutocomplete.getPlace();
        if (!place.place_id) {
          return;
        }
        const origin = place.name;
        setPickup_Location(place?.name)
        setLocationError("")
        const destination = destiantionRef.current.value;
        calculateAndFetchRoute(origin, destination);
      });

      destinationAutocomplete.addListener("place_changed", () => {
        const place = destinationAutocomplete.getPlace();
        if (!place.place_id) {
          return;
        }
        const destination = place.name;
        setDestination_Location(place?.name)
        const origin = originRef.current.value;
        calculateAndFetchRoute(origin, destination);
      });
    } catch (error) {

    }
  }, [originRef?.current?.value, destiantionRef?.current?.value]);

  const getFixedFare = async (event) => {
    event && event.preventDefault();
    let { date, time } = getCurrentTime();
    let travelDate = Travelling_Date ? getCurrentTime(Travelling_Date)?.date : date
    let travelTime = Travelling_Time ? `${Travelling_Time}:00` : time
    if (Book_For_Later === 'True' && Fixed_Price === 'False') {
      setBook_For_Later('False')
      setFixed_Price('True')
      try {
        const response = await axios.post(
          "https://taxi2.thecaptaintaxis.com.au/CostCalculationsAPI/",
          {
            Start_Latitude,
            Start_Longitude,
            Pickup_Location,
            Destination_Latitude,
            Destination_Longitude,
            Taxi_Type: Taxi_Type ? Taxi_Type : 'Maxi',
            Fixed_Price,
            Book_For_Later: showopen ? 'True' : 'False',
            Travelling_Date: travelDate,
            Travelling_Time: travelTime,
          }
        );
        if (response.status === 200) {
          setloadingFlag(false)
          setTotalFare(response.data);
          setTaxi_Type('Maxi')
        }
        return true
      } catch (err) {
        setloadingFlag(false)
        console.log(err);
      }
    } else {
      setBook_For_Later('True')
      setFixed_Price('False')
      try {
        const response = await axios.post(
          "https://taxi2.thecaptaintaxis.com.au/CostCalculationsAPI/",
          {
            Start_Latitude,
            Start_Longitude,
            Pickup_Location,
            Destination_Latitude,
            Destination_Longitude,
            Taxi_Type,
            Fixed_Price,
            Book_For_Later: showopen ? 'True' : 'False',
            Travelling_Date: travelDate,
            Travelling_Time: travelTime,
          }
        );

        if (response.status === 200) {
          setloadingFlag(false)
          setTotalFare(response.data);
          setTaxi_Type('Maxi')
        }
        return true
      } catch (err) {
        setloadingFlag(false)
        console.log(err);
      }
    }
    setswitchBtn(e => !e)
  }

  useEffect(() => {
    try {
      if (directionsResponse?.status && !homeRoute) {
        (async () => {
          setcircularProgress(true)
          let data = await callFixedFare()
          setTotalFare(data)
          setdirectionFlag(true)
        })()
      } else {
        sethomeRoute(false)
      }
    } catch (error) {

    }
  }, [directionsResponse])

  const PassengerDetails = async () => {
    try {
      if (emailValidation(userEmail)) {
        setvalidateEmail(false)
      } else {
        setvalidateEmail(true)
        return;
      }
      if (!Passenger_Name || !Contact_Number || !userEmail || !Payment_Method) {
        setwarningObj({ open: true, message: "Please fill all required fields", type: "error" })
        return;
      }
      if (totalFare[Taxi_Type] === 'WC' && !ttssDropdown?.value) {
        setwarningObj({ open: true, message: "Please fill all required fields", type: "error" })
        return;
      }

      const paidTTSS = ttssDropdown?.value === 'Yes' ? true : false || false

      setloadingFlag(true)
      let { date, time } = getCurrentTime();
      let travelDate = Travelling_Date ? Travelling_Date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2') : date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2');
      let travelTime = Travelling_Time ? Travelling_Time : time
      const responsepassenger = await axios.post(
        "https://taxi2.thecaptaintaxis.com.au/PassengerDetailsAPI/",
        {
          Passenger_Name, Contact_Number, Driver_Instructions, Payment_Method,
          Pickup_Location: Pickup_Location,
          Dropoff_Location: destination_Location,
          Date_Time: `${travelDate}T${travelTime}`,
          Booking_Type: Taxi_Type,
          Book_For_Later: showopen ? true : false,
          Paid_Via_TTSS: paidTTSS,
          Email: userEmail ? userEmail?.toLowerCase() : "",
          Price: totalFare[Taxi_Type]?.Lower_Estimate ? `${totalFare[Taxi_Type]?.Lower_Estimate} - ${totalFare[Taxi_Type]?.Upper_Estimate}` : totalFare[Taxi_Type]?.Estimate
        }
      );
      setloadingFlag(false)

      if (responsepassenger.status === 200) {
        toast.success(responsepassenger.data.Message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setPassenger_Name("");
        setContact_Number("");
        setDriver_Instructions("");
        setUserEmail("")
        setPayment_Method("");
        originRef.current.value = "";
        destiantionRef.current.value = "";
        setTotalFare();
        setDestination_Location("");
        setPickup_Location("");
        setLocationError("")
      } else if (responsepassenger.data.Message.includes('Passenger Details saved')) {
        toast.success(responsepassenger.data.Message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleOpen()
      } else {
        toast.error('Something went wrong!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      setloadingFlag(false)
      console.log(err);
    }
  };

  const tabshow = (value) => {
    if (tab === value) {
      setTab(null);
    } else {
      setTab(value);
    }
  };

  const tabshow1 = (value) => {
    if (tab1 === value) {
      setTab1(null);
    } else {
      setTab1(value);
    }
  };

  const CarsShow = async (id) => {

    if (selectedCar == id) {
      setSelectedCar(0)
    }
    else {
      setSelectedCar(id);
    }
    if (id === 4) {
      setTtssDropdown({ value: "", wcFglag: true })
      return
    }

    if (totalFare && totalFare[Taxi_Type]?.Estimate) {
      let amount = totalFare[Taxi_Type]?.Estimate
      await callPaymentIntend(amount)
    } else {
      let data = await callFixedFare()
      let getCar = Cars.find(x => x.id === id)
      let amount = data[getCar?.type]?.Estimate
      await callPaymentIntend(amount)
    }
  };

  const handleTtssChange = async (e) => {
    try {
      let value = e.target.value
      setTtssDropdown({ value: e.target.value, wcFglag: ttssDropdown.wcFglag })
      let amount = 0
      let updateFares = { ...totalFare }
      if (value === 'Yes') {
        if (totalFare && Taxi_Type === 'WC') {
          if (updateFares['WC']?.Estimate) {
            updateFares['WC'].Estimate = totalFare['WC']?.Estimate / 2
          } else {
            updateFares['WC'].Lower_Estimate = totalFare['WC']?.Lower_Estimate / 2
            updateFares['WC'].Upper_Estimate = totalFare['WC']?.Upper_Estimate / 2
          }
        }
      } else {
        if (totalFare && Taxi_Type === 'WC') {
          if (updateFares['WC']?.Estimate) {
            updateFares['WC'].Estimate = totalFare['WC']?.Estimate * 2
          } else {
            updateFares['WC'].Lower_Estimate = totalFare['WC']?.Lower_Estimate * 2
            updateFares['WC'].Upper_Estimate = totalFare['WC']?.Upper_Estimate * 2
          }
        }
      }
      if (!updateFares['WC']?.Lower_Estimate) {
        amount = value === 'Yes' ? totalFare['WC']?.Estimate / 2 : totalFare['WC']?.Estimate
      } else {
        let data = await callFixedFare()
        amount = value === 'Yes' ? data['WC']?.Estimate / 2 : data['WC']?.Estimate
      }
      setTotalFare(updateFares)
      callPaymentIntend(amount)
    } catch (error) {

    }
  }

  const CarsHide = (id) => {
    if (selectedCar == -1)
      setSelectedCar(0);
    else
      setSelectedCar(-1);
  };

  const validateText = (type) => {
    let infoText = ''
    switch (type) {
      case 'Maxi':
        infoText = "If you’re travelling with 5 to 11 or less Passengers, our Maxi are usually the quickest and most efficient way to get from a to b."
        break;
      case 'SUV':
        infoText = "If you’re travelling with 5 to 7 or less Passengers, our SUV are usually the quickest and most efficient way to get from a to b."
        break;
      case 'Sedan':
        infoText = "If you’re travelling with 4 or less Passengers, our Sedans are usually the quickest and most efficient way to get from a to b."
        break;
      case 'WC':
        infoText = "Need to travel with your wheels? We have a range of vehicles and qualified Drivers who'll get you there, and can help you in and out of the cab if you need."
        break;
      case 'Delivery':
        infoText = "Need to get something somewhere right now? 13cabs can deliver. If it fits in one of our vehicles, we’ll deliver it door to door for the price of a cab fare."
        break;
      default:
        infoText = "Details coming soon"
    }
    return infoText
  }

  const handleViewDetails = (carDetails) => {
    setviewDetails(true)
    let info = validateText(carDetails.type)
    let obj = { ...carDetails, info }
    setviewDetailsObj(obj)
  }

  const handleEmailValue = (e) => {
    let value = e.target.value
    setUserEmail(value)
  }

  const handleCurrentLocationClick = () => {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      const latitude = pos.coords.latitude;
      const longitude = pos.coords.longitude;

      try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBnAvn0DGsT--D0xLW6l5JQD6Razy9gnvM`);


        if (response.data.results.length > 0) {
          const userLocation = response?.data?.results[0]?.formatted_address;

          if (
            userLocation.toLowerCase().includes("nsw") ||
            userLocation.toLowerCase().includes("sydney") ||
            userLocation.toLowerCase().includes("new south wales") ||
            userLocation.toLowerCase().includes("australia")
          ) {
            setPickup_Location(userLocation)
            setLocationError('')
          } else {
            setLocationError('Booking are only available in Sydney, Australia.')
            setPickup_Location('')
          }
        } else {
          console.error("No address found for the provided coordinates.");
          setLocationError('Please enter address manually.')
        }
      } catch (error) {
        console.error("Error fetching location:", error);
        setLocationError('Error fetching location. Please try again later.');
      }
    })
  }

  if (!isLoaded) {
    return <p>loading....</p>;
  }

  return (
    <>
      {warningObj.open &&
        <WarningSnackBar
          warningObj={warningObj}
          setwarningObj={setwarningObj}
        />}
      {loadingFlag && <CustomLoader
        open={loadingFlag}
        stopLoading={setloadingFlag}
      />}

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div className="d-flex flex-column gap-4 justify-content-between">
            <div>
              <div className="d-flex gap-2 justify-content-start items-align-center flex-wrap">
                <MdApproval size={35} color="indigo" />
                <h3 style={{ color: 'indigo' }}>Booking Successful</h3>
              </div>
              <div>
                <p>Thank you for choosing The Captain Taxis for a comfortable ride. We will contact you soon.</p>
                <a className="text-dark" href="/contact_us">For any queries, contact us.</a>
              </div>
            </div>
            <div className="text-end">
              <button onClick={handleClose} className="aboutmorebtn">CLOSE</button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* UPPER TAB */}
      <div className="bookingaridenavtab desktopboxdisplay">
        <div className="bookingaridenavtableft">
          {/* <div className="col mt-2">
            <abbr title="0420 966 387">
              <BiSolidPhoneCall
                className="mx-2"
                style={{ fontSize: '28px', color: "#4b0082" }}
              />
            </abbr>
            <span style={{ fontSize: '19px' }}>0420 966 387</span>
          </div> */}
          <div className="col mt-1">
            <button className="karachimodeledit" style={{ fontWeight: "bold" }}>
              Australia
            </button>
          </div>
        </div>
        <ToastContainer />
        <div className="bookingaridenavtabright">
          <div className="col-10 d-flex">
            <div className="col-6" style={{ textAlign: "right" }}>
              <button
                className={tab1 === "tab 1" ? "mapviewtabactive" : "mapviewtab"}
                onClick={() => tabshow1("tab 1")}
              >
                <BiMapAlt className="mx-2" />
                Map View
              </button>
            </div>
            <div className="col-6" style={{ textAlign: "left" }}>
              <button
                className={tab1 === "tab 2" ? "mapviewtabactive" : "mapviewtab"}
                onClick={() => tabshow1("tab 2")}
              >
                <HiOutlineViewGrid className="mx-2" />
                Schedule View
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="booking-containermain">
        <div className="row-bookrideform">
          {viewDetails ?
            // CAR DETAILS
            <div className="columnbookrideform1" style={{ background: "white" }}>
              <ViewCarDetails closeDetails={setviewDetails} selectedDetails={viewDetailsObj} setSelectedCar={CarsShow} />
            </div>
            :
            // WHOLE RIGHT DIV
            <div className="columnbookrideform1"
              style={{ backgroundColor: "#fff" }}
            >
              {/* DESKTOP TAB */}
              {/* <div id="bookridetabbtn" className="desktopboxdisplay">
                <button
                  className={
                    tab === "1" ? "bookridetabbtnactive" : "bookridetabbtn"
                  }
                  onClick={() => tabshow("1")}
                >
                  Quick Book
                </button>
                <button
                  className={
                    tab === "2" ? "bookridetabbtnactive" : "bookridetabbtn"
                  }
                  onClick={() => tabshow("2")}
                >
                  New Booking
                </button>
              </div> */}

              {/* MOBILE TAB */}
              <div id="bookridetabbtn" className="">
                <button
                  className={
                    tab === "2" ? "bookridetabbtnactive" : "bookridetabbtn"
                  }
                  onClick={() => tabshow("2")}
                >
                  New Booking
                </button>

                <button
                  className={
                    tab === "1" ? "bookridetabbtnactive" : "bookridetabbtn"
                  }
                  onClick={() => tabshow("1")}
                >
                  Quick Book
                </button>
                {/* <button
                  className={
                    tab === "3" ? "bookridetabbtnactive" : "bookridetabbtn"
                  }
                  onClick={() => tabshow("3")}
                >
                  Active
                </button> */}
              </div>

              {/* QUICK BOOK */}
              {tab === "1" && (
                <div className="quick-book-panel">
                  <h5 className="pt-4 recenttriptitle mx-4 text-center">We Are<br /> comming soon...</h5>
                  {/* <div id="bookridetabRecent">
                  </div> */}
                </div>
              )}

              {/* NEW BOOKING */}
              {tab === "2" && (
                <div className="booking-form-panel">
                  <div className="booking-form-content">
                    <div id="mapmobilbedisplay">
                      <GoogleMap
                        center={center}
                        zoom={15}
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        options={{
                          zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                        onLoad={(map) => setMap(map)}
                      >
                        <Marker
                        // position={center} 
                        />
                        {directionsResponse && (
                          <DirectionsRenderer directions={directionsResponse} />
                        )}
                      </GoogleMap>
                    </div>

                    <div className="booking-form-group booking-form-named-step ">
                      {/* STEP 1 */}
                      <div className="form-section-header">
                        Step 1 of 4
                        <span className="brand-color"> Booking details</span>
                      </div>

                      {/* INPUTS */}
                      <div className="booking-fields-panel">

                        {/* PICKUP INPUT */}
                        <div className="booking-address-field">
                          <TextField
                            id="outlined-basic"
                            label="Add pickup (required)"
                            className="w-100"
                            variant="outlined"
                            inputRef={originRef}
                            value={Pickup_Location}
                            error={Boolean(locationError)}
                            helperText={locationError}
                            onChange={(e) => {
                              setPickup_Location(e.target.value)
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FiberManualRecordIcon
                                    style={{ color: "#4b0082" }}
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MyLocationIcon onClick={handleCurrentLocationClick} fontSize="25" style={{ color: "indigo", cursor: 'pointer' }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <div></div>
                        </div>

                        {/* DROPOFF INPUT */}
                        <div className="booking-address-field mt-4">
                          <TextField
                            id="outlined-basic"
                            label="Add destination (required)"
                            variant="outlined"
                            className="w-100"
                            inputRef={destiantionRef}
                            value={destination_Location}
                            onChange={(e) => setDestination_Location(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FiberManualRecordIcon
                                    style={{ color: "#4a4a4a" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <div></div>
                        </div>


                        {/* BOOK NOW, LATE, SCHEDULE */}
                        <div className="booking-address-field mt-4">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  onClick={() => setShowopen(false)}
                                  checked={!showopen}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  Book for now
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  onClick={() => setShowopen(true)}
                                  checked={showopen}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  Book for later
                                </label>
                              </div>
                            </div>
                            {showopen && (
                              <>
                                <div className="col-md-6 mt-4">
                                  <label>Pickup date</label>
                                  <input
                                    className="input-filed mb-3"
                                    type="date"
                                    name="Travelling_Date"
                                    value={Travelling_Date}
                                    onChange={(e) =>
                                      setTravelling_Date(e.target.value)
                                    }
                                    placeholder="pickupdate"
                                  />
                                </div>
                                <div className="col-md-6 mt-4">
                                  <label>Pickup time</label>
                                  <input
                                    className="input-filed mb-3"
                                    type="time"
                                    name="Travelling_Time"
                                    value={Travelling_Time}
                                    onChange={(e) => setTravelling_Time(e.target.value)}
                                    placeholder="pickupdate"
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {/* SELECT FIXED PRICE CONTAINER */}
                        <div className="price-guarantee-toggle-div">
                          <div className="on-account-toggle-section price-guarantee-toggle d-flex px-1">
                            <div className="row">
                              <div className="col-10 mt-3">
                                <span className="brand-color">Select Fixed Price</span></div>
                              <div className="col-2 mt-3">
                                <label className="switchbtn">
                                  <input type="checkbox" checked={switchBtn}
                                    onChange={getFixedFare}
                                  />
                                  <p className="switchslider round"></p>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* SELECT SERVICE CONTAINER */}
                        <div className="selected-vehicle-bar-panel">
                          <div
                            className="vehicle-selector-label-area"
                            onClick={() => CarsHide((-1))}
                          >
                            <span>Select your service</span>
                            <span aria-label="Hide services" >
                              {selectedCar === -1 ? (
                                <img src="https://prod-13orange-cdn-endpoint.azureedge.net/Arrows/chevron-up.svg" />
                              ) : (
                                <img src="https://prod-13orange-cdn-endpoint.azureedge.net/Arrows\ArrowDownBlack.svg" />
                              )}
                            </span>
                          </div>
                          <hr className="service-section-separator " />
                        </div>
                      </div>


                      {/* CARS */}
                      {cars === false ? (
                        <div className="service-selector" style={(selectedCar == -1) ? { display: 'none' } : { display: 'block' }}>
                          {
                            Cars?.map((car) => {
                              return (
                                <>
                                  <label style={(car.id == selectedCar || selectedCar == 0) ? { display: 'block' } : { display: 'none' }}
                                    className={car.type === 'Maxi' ? 'mobile-condition-container1' : 'mobile-condition-container highlight-selected-service'}
                                  >
                                    {/* CAR ONCLICK INPUT */}
                                    <input
                                      type="radio"
                                      value={car.type}
                                      //  checked={car.type === 'Maxi'}
                                      onClick={() => CarsShow((car.id))}
                                      checked={Taxi_Type === car.type}
                                      onChange={(e) => setTaxi_Type(e.target.value)}
                                      style={{ display: 'none' }}
                                    />

                                    {/* CARS */}
                                    <div>
                                      <div className="mobile-condition-content">
                                        <div className="selected-vehicle-bar-image false" style={{ width: "80px", height: '70px' }}>
                                          <img
                                            src={car.img}
                                            alt="vehicles"
                                            style={{ objectFit: 'cover', width: "100%", marginTop: '10px' }}
                                          />
                                        </div>
                                        <div className="font_visby">
                                          <span className="bar-info-row-title-1">{car.text}</span>
                                          <p className="bar-info-row-1 font_helvetica">
                                            {car?.details}
                                          </p>
                                        </div>

                                        {/* <div
                                          className="service-info-block"
                                          aria-label="View information about this service"
                                        >
                                            <img
                                              src="https://prod-13orange-cdn-endpoint.azureedge.net/Info\info_grey_round.svg"
                                              className="service-info-icon"
                                              onClick={() => handleViewDetails(car)}
                                            />
                                        </div> */}

                                        <div className="bar-margin-left font_helvetica bg-danger">
                                          <span className="fe-title" style={{ right: 25, top: 15 }}>Fare Estimate</span>
                                          <img
                                            src="https://prod-13orange-cdn-endpoint.azureedge.net/Info\info_grey_round.svg"
                                            className="service-info-icon fe-title"
                                            style={{ right: 0, top: 15 }}
                                            onClick={() => handleViewDetails(car)}
                                          />
                                          <p className="fe-value fe-value-not-available">
                                            {totalFare ?
                                              (
                                                <span className="d-flex">
                                                  {totalFare[car?.type]?.Lower_Estimate ?
                                                    <span>
                                                      ${Number(totalFare[car?.type]?.Lower_Estimate).toFixed(2)}-$
                                                      {Number(totalFare[car?.type]?.Upper_Estimate).toFixed(2)}
                                                    </span>
                                                    :
                                                    <span>
                                                      {circularProgressFlag && <CircularProgress
                                                        size={22}
                                                        sx={{ ...circularProgessStyle, left: '-2.5rem' }}
                                                      />}
                                                      <BiLock style={{ color: "#4b0082" }} />
                                                      <span style={{ marginLeft: '4px' }}>${Number(totalFare[car?.type]?.Estimate).toFixed(2)}</span>
                                                    </span>
                                                  }
                                                </span>
                                              ) : (
                                                <>
                                                  <div style={{ position: circularProgressFlag && 'relative', left: circularProgressFlag && '2.8rem' }}>
                                                    <span >Dest required</span>
                                                    {circularProgressFlag &&
                                                      <CircularProgress
                                                        size={22}
                                                        sx={{
                                                          color: 'indigo',
                                                          position: 'absolute',
                                                          top: '50%',
                                                          left: '-10rem',
                                                          marginTop: '-12px',
                                                          marginLeft: '-12px',
                                                        }}
                                                      />}
                                                  </div>
                                                </>
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  </label>
                                </>
                              )
                            })
                          }

                        </div>
                      ) : (
                        <div className="service-selector">
                          <label className={Taxi_Type === 'Maxi' ? 'mobile-condition-container1' : 'mobile-condition-container highlight-selected-service'}>
                            <input
                              type="radio"
                              value="Maxi"
                              checked={Taxi_Type === 'Maxi'}
                              onChange={(e) => setTaxi_Type(e.target.value)}
                              style={{ display: 'none' }}
                            />
                            <div>
                              <div className="mobile-condition-content">
                                <div className="selected-vehicle-bar-image false">
                                  <img
                                    src="https://prod-13orange-cdn-endpoint.azureedge.net/Vehicles\Sedan.png"
                                    alt="Next Available"
                                  />
                                </div>
                                <div className="font_visby">
                                  <span className="bar-info-row-title-1">Maxi</span>
                                  <p className="bar-info-row-1 font_helvetica">
                                    1-4 passengers
                                  </p>
                                </div>
                                <div
                                  className="service-info-block"
                                  aria-label="View information about this service"
                                >
                                  <img
                                    src="https://prod-13orange-cdn-endpoint.azureedge.net/Info\info_grey_round.svg"
                                    className="service-info-icon"
                                  />
                                </div>
                                <div className="bar-margin-left font_helvetica">
                                  <span className="fe-title">Fare Estimate</span>
                                  {Book_For_Later === "True" ? (
                                    <p className="fe-value fe-value-not-available">
                                      {totalFare ? (
                                        <div>
                                          <span>
                                            ${totalFare.Maxi.Lower_Estimate}-$
                                            {totalFare.Maxi.Upper_Estimate}
                                          </span>
                                        </div>
                                      ) : (
                                        <>
                                          <div style={{ position: circularProgressFlag && 'relative', left: circularProgressFlag && '2.8rem' }}>
                                            <span >Dest required </span>
                                            {circularProgressFlag &&
                                              <CircularProgress
                                                size={22}
                                                sx={circularProgessStyle}
                                              />}
                                          </div>
                                        </>
                                      )}
                                    </p>
                                  ) : (
                                    <p className="fe-value fe-value-not-available">
                                      {totalFare ? (
                                        <span>
                                          ${totalFare.Maxi.Estimate}
                                        </span>
                                      ) : (
                                        <>
                                          <div style={{ position: circularProgressFlag && 'relative', left: circularProgressFlag && '2.8rem' }}>
                                            <span >Dest required </span>
                                            {circularProgressFlag &&
                                              <CircularProgress
                                                size={22}
                                                sx={circularProgessStyle}
                                              />}
                                          </div>
                                        </>
                                      )}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      )}

                      {/* STEP 2 */}
                      <div className="form-section-header">
                        Step 2 of 4
                        <span className="brand-color"> Contact details</span>
                      </div>
                      {ttssDropdown.wcFglag &&
                        <div className="booking-fields-panel">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              TTSS
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="TTSS"
                              value={ttssDropdown?.value}
                              onChange={(e) => { handleTtssChange(e) }}>
                              <MenuItem value={"Yes"}>Yes</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>}
                      <div className="booking-fields-panel">
                        <TextField
                          className="booking-addresswidth"
                          id="outlined-basic"
                          label="Passenger name"
                          variant="outlined"
                          name="Passenger_Name"
                          value={Passenger_Name}
                          onChange={(e) => setPassenger_Name(e.target.value)}
                        />
                      </div>
                      <div className="booking-fields-panel d-flex">
                        <div className="col-3">
                          <TextField
                            className="booking-addresswidth"
                            id="outlined-basic"
                            label="+61"
                            value="+61"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={'assets/AustralianFlag.png'}
                                    alt="Australian Flag"
                                    height="20"
                                    width="27"
                                    style={{ borderRadius: "4px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="col-9 mx-1">
                          <TextField
                            className="booking-addresswidth"
                            id="outlined-basic"
                            label="Contact number"
                            variant="outlined"
                            name="Contact_Number"
                            value={Contact_Number}
                            onChange={(e) => setContact_Number(e.target.value?.replace(/[^0-9]/g, ''))}
                          />
                        </div>
                      </div>
                      <div className="booking-fields-panel">
                        <TextField
                          className="booking-addresswidth"
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          name="email"
                          value={userEmail}
                          onChange={handleEmailValue}
                        />
                        {validateEmail && <span style={{ color: 'red', fontSize: '12px' }}>Invalid Email</span>}
                      </div>

                      {/* STEP 3 */}
                      <div className="form-section-header">
                        Step 3 of 4
                        <span className="brand-color"> Driver instruction</span>
                      </div>
                      <div className="booking-fields-panel">
                        <textarea
                          placeholder="Notes for driver"
                          id="textarea"
                          name="Driver_Instructions"
                          value={Driver_Instructions}
                          onChange={(e) => setDriver_Instructions(e.target.value)}
                        ></textarea>
                        <h6 style={{ textAlign: "right", fontSize: "12px" }}>
                          320 characters left
                        </h6>
                      </div>


                      {/* STEP 4 */}
                      <div className="form-section-header">
                        Step 4 of 4
                        <span className="brand-color"> Payment method</span>
                      </div>

                      <div className="booking-fields-panel">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Please select
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Please select"
                            value={Payment_Method}
                            onChange={(e) => {
                              setPayment_Method(e.target.value)
                              if (e?.target?.value === 'credit card') {
                                if (totalFare) setCardPayment(true)
                              } else {
                                setCardPayment(false)
                              }
                            }}
                          >
                            <MenuItem value={"cash"}>
                              Cash
                            </MenuItem>
                            <MenuItem value={"cab charge"}>Cab Charge</MenuItem>
                            <MenuItem value={"credit card"}>Credit Card</MenuItem>
                          </Select>
                        </FormControl>

                        {cardPayment &&
                          <Elements stripe={stripePromise} options={stripeOptions} >
                            <StripePayment open={cardPayment} closeModal={closePaymentModal} PaymentIntendResponse={PaymentIntendResponse} />
                          </Elements>}

                        <div className="mx-auto text-center">
                          <button
                            className="aboutmorebtn1 mt-4"
                            onClick={PassengerDetails}
                          >
                            Request booking
                          </button>
                          <p className="mt-3" style={{ fontSize: "12px" }}>
                            I have read and agreed to the{" "}
                            <span style={{ color: "#4b0082" }}>
                              Collection Statement, {" "}
                            </span>
                            <a href="/policy">
                              <span style={{ color: "#4b0082", marginLeft: "0px" }}>
                                Privacy Policy
                              </span>
                            </a>
                            <span>{" "} and {" "}</span>
                            <a href="/policy?selected=terms">
                              <span style={{ color: "#4b0082", marginLeft: "0px" }}>
                                Terms and Conditions
                              </span>
                            </a>
                          </p>
                          <p className="text-center" style={{ fontSize: "12px" }}>
                            THE CAPTAIN TAXIS © 2024
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* ACTIVE */}
              {/* {tab === "3" && (
                <div className="quick-book-panel">
                  <h5 className="pt-4 recenttriptitle mx-4 text-center" style={{ color: "#4a4a4a" }}>No active <br />booking found</h5>
                </div>
              )} */}
            </div>
          }

          {/* GOOGLE MAPS - LEFT DIV */}
          <div
            className="columnbookrideform desktopboxdisplay"
            style={{ backgroundColor: "#f0f0f0", height: '100vh' }}
          >
            {/* MAP VIEW */}
            {tab1 === "tab 1" && (
              <div
                style={{ width: '100%', height: '100%' }}
              >
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={(map) => setMap(map)}
                >
                  <Marker
                  // position={center} 
                  />
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap>
              </div>
            )}
            {/* SCHEDULE VIEW */}
            {tab1 === "tab 2" && (
              <div className="Pendingpick-upboxmain">
                <div style={{ display: 'grid', placeItems: 'center', height: "100%" }}>
                  <h1
                    className="m-5"
                    style={{ color: "black", fontWeight: "900" }}
                  > Coming Soon</h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div >
    </>
  );
};
export default BookTap;