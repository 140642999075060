import { Link } from 'react-router-dom'
import '../../style/parallax.css'
import { BsArrowRight } from 'react-icons/bs'

export const ParallaxDiv = ({ image, title, content, enable, attach, position }) => {
    return (
        <div style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})`, backgroundAttachment: `${attach}`, backgroundPositionX: `${position}` }} className='parallax-con'>
            <div>
                <div>
                    <h1>{title}</h1>
                    <p className='my-4'>{content}</p>
                    {enable && <Link to="/book_taxi"><button className="aboutmorebtn">Book a Taxi <BsArrowRight /></button></Link>}
                </div>
            </div>
        </div>
    )
}