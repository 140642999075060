import React, { useState } from 'react'
import { BiSolidTaxi, BiSolidUserPlus, BiSolidPhoneCall } from 'react-icons/bi';
import { FiMail } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import "../../style/HeaderMenu.css";
import { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { FaFacebook, FaFacebookF, FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';
import "../../style/home.css";
import { FaLinkedin, FaYoutube } from 'react-icons/fa6';

export const HeaderMenu = () => {
    const [reavealProperty, setreavealProperty] = useState(null)

    const handleStickyNvabar = () => {
        try {
            if (window.innerWidth <= 800) {
                setreavealProperty(false)
                return
            }
            let navbar = document.getElementsByClassName("top-nav")[0];
            let sticky = navbar.offsetTop;
            if (window.scrollY >= 2) {
                setreavealProperty(true)
                setTimeout(() => {
                    document.getElementsByClassName('navbarLogo')[0].style.display = 'block'
                }, 0)
            } else {
                setreavealProperty(false)
                setTimeout(() => {
                    document.getElementsByClassName('navbarLogo')[0].style.display = 'none'
                }, 0)
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        window.addEventListener("scroll", handleStickyNvabar);
        return () => {
            window.removeEventListener("scroll", handleStickyNvabar);
        }
    }, [])

    return (
        <>
            <div className='menu-container'>
                <div className='d-flex justify-content-center navbarmain-logo mb-2'>
                    <img src="/assets/Logo1.png" alt="logo" className='headermenuimageMain' />
                </div>
                <div className='d-flex justify-content-center align-items-center flex-wrap gap-2'>
                    <div className='container-item'>
                        <BiSolidPhoneCall className='BiSolidPhoneCallheaderNav' />
                        <div className='contactContents'>
                            <span>Call Us Now</span><br />
                            <a href="tel:+0420966387" className='mailheadertext text-dark'>0420 966 387</a>
                        </div>
                    </div>
                    <div className='container-item'>
                        <FiMail className='BiSolidPhoneCallheaderNav' />
                        <div className='contactContents'>
                            <span>Email Now</span><br />
                            <a href="mailto:Info@thecaptaintaxis.com.au" className='mailheadertext text-dark'>Info@thecaptaintaxis.com.au</a>
                        </div>
                    </div>
                    <div className='container-item'>
                        <NavLink to="/book_taxi"><button className='booktaxi-btn'><BiSolidTaxi className='textBookrideFont' />&nbsp;BOOK A Ride</button></NavLink>
                    </div>
                </div>
            </div>

            <div className='row'>
                {reavealProperty ?
                    <div className='col-lg-12'>
                        <Fade top>
                            <section className="top-nav sticky">
                                <Navbar scroll={true} />
                            </section>
                        </Fade>
                    </div>
                    :
                    <>
                        <div className='col-lg-2' ></div>
                        <div className='col-lg-12' >
                            {/* <section className="top-nav" style={{ clipPath: window.innerWidth <= 800 ? 'none' : 'polygon(0 0, 100% 0, 100% 100%, 1% 100%)' }}> */}
                            <section className="top-nav">
                                <Navbar />
                            </section>
                            <div className="dropdown-content">
                                <a href="#">Online Booking</a>
                                <a href="#">City Transport</a>
                                <a href="#">Airport Transport</a>
                                <a href="#">Buisness Transport</a>
                                <a href="#">Regular Transport</a>
                                <a href="#">Tour Transport</a>
                            </div>
                        </div>
                        <div className='col-lg-1'></div>
                    </>
                }

            </div>
        </>
    )
}

const Navbar = ({ scroll }) => {

    const displayDropdown = (property) => {
        try {
            if (window.innerWidth < 800) return
            let element = document.getElementsByClassName('dropdown-content')[0]
            let servicesElement = document.getElementsByClassName("services-dropdown")[0];
            let leftPosition = servicesElement.offsetLeft;
            element.style.marginLeft = `${leftPosition}px` ?? 20
            element.style.display = property
        } catch (error) {

        }
    }
    return (
        <>
            <div className='navbarLogo'>
                <h4>
                    <NavLink to='/' style={{ fontFamily: 'Star7, sans-serif', letterSpacing: '1px' }} className="text-decoration-none text-white">
                        THE CAPTAIN TAXIS
                    </NavLink>
                </h4>
            </div>
            <input id="menu-toggle" type="checkbox" />
            <label className='menu-button-container' htmlFor="menu-toggle">
                <div className='menu-button'></div>
            </label>
            <ul className="navbar-menu">
                <li><NavLink to='/'>Home</NavLink></li>
                <li onMouseOver={() => displayDropdown('block')} onMouseLeave={() => displayDropdown('none')}>
                    <NavLink to='/services' className={'services-dropdown'} > Services </NavLink>
                </li>
                <li><NavLink to='/vehicles'> Vehicles </NavLink></li>
                <li><NavLink to='/about'> About Us </NavLink></li>
                <li><NavLink to='/join_as_a_driver'> Driver  </NavLink></li>
                <li><NavLink to='/contact_us'> Contact Us </NavLink></li>
                {
                    (scroll || window.innerWidth <= 800) ?
                        <>
                            <li className='d-flex align-items-center' style={{ gap: 15, marginLeft: 'auto' }}>
                                <span>Follow Us:</span>
                                <li><NavLink to="https://www.facebook.com/thecaptaintaxis" target="_blank"><FaFacebook className='facebook text-white' /></NavLink></li>
                                <li><NavLink to="https://www.instagram.com/thecaptaintaxis/?hl=en" target="_blank"><FaInstagram className='instagram text-white' /></NavLink></li>
                                <li><NavLink to="https://www.linkedin.com/in/the-captain-taxis-452473288" target="_blank"><FaLinkedin className='linkedin text-white' /></NavLink></li>
                                <li><NavLink to="https://www.youtube.com/@thecaptaintaxis" target="_blank"><FaYoutube className='youtube text-white' /></NavLink></li>
                                <li><NavLink to="https://t.me/thecaptaintaxis" target="_blank"><FaTelegramPlane className='telegram text-white' /></NavLink></li>
                            </li>
                        </>
                        :
                        <>
                            <span className='followus-section'>
                                <span>Follow Us:</span>
                                <li><NavLink to="https://www.facebook.com/thecaptaintaxis" target="_blank"><FaFacebook className='facebook text-white' /></NavLink></li>
                                <li><NavLink to="https://www.instagram.com/thecaptaintaxis/?hl=en" target="_blank"><FaInstagram className='instagram' color='white' /></NavLink></li>
                                <li><NavLink to="https://www.linkedin.com/in/the-captain-taxis-452473288" target="_blank"><FaLinkedin className='linkedin text-white' /></NavLink></li>
                                <li><NavLink to="https://www.youtube.com/@thecaptaintaxis" target="_blank"><FaYoutube className='youtube text-white' /></NavLink></li>
                                <li><NavLink to="https://t.me/thecaptaintaxis" target="_blank"><FaTelegramPlane className='telegram text-white' /></NavLink></li>
                            </span>
                        </>
                }
            </ul>
        </>
    )
}