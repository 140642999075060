import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderMenu } from "../Home/HeaderMenu"
import axios from 'axios';
import { Footer } from "../footer/Footer"

const QRPage = () => {
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [error, setError] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://taxi2.thecaptaintaxis.com.au/QRAPI/');
                console.log(response.data)
                if (response.data) {
                    setError()
                    setRedirectUrl(response.data.link.startsWith('http') ? response.data.link : `https://${response.data.link}`);
                } else {
                    setError('Something went wrong! Please try again later.')
                    console.error('Invalid response:', response);
                }
            } catch (error) {
                setError('Something went wrong! Please try again later.')
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [redirectUrl]);

    return (
        <>
            <HeaderMenu />
            <div className="container d-flex justify-content-center align-items-center flex-column gap-1" style={{ height: '75svh' }}>
                <span className="loader"></span>
                <p>{error ? error : 'Redirecting...'}</p>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default QRPage