import { Footer } from "../footer/Footer";
import Typed from "react-typed";
import Fade from "react-reveal/Fade";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HeaderMenu } from "../Home/HeaderMenu";
import CustomLoader from '../Loader/Loader'
import { Accordion, AccordionDetails, AccordionSummary, Modal } from "@mui/material";
// import Email from 'smtpjs';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ParallaxDiv } from "../parallaxDiv/parallaxDiv";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { FcApproval } from "react-icons/fc";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '100%',
  height: 'auto',
  bgcolor: '#c0c0c0',
  border: 'none',
  boxShadow: 24,
  p: 2.5,
  borderRadius: 3
};

const JoinasaDriver = () => {
  const navigate = useNavigate()
  const [First_Name, setFirst_Name] = useState();
  const [Last_Name, setLast_Name] = useState();
  const [DOB, setDOB] = useState();
  const [Street_Address, setStreet_Address] = useState();
  const [Fleets_Taxi, setFleets_Taxi] = useState();
  const [Operator_Name, setOperator_Name] = useState();
  const [Operator_Number, setOperator_Number] = useState();
  const [Taxi_Made, setTaxi_Made] = useState();
  const [Vehicles_VIN, setVehicles_VIN] = useState();
  const [Vehicles_Model, setVehicles_Model] = useState();
  const [Registration_Expiry_Date, setRegistration_Expiry_Date] = useState();
  const [Plate_Number, setPlate_Number] = useState();
  const [Vehicle_Color, setVehicle_Color] = useState();
  const [Suburb, setSuburb] = useState();
  const [Mobile_Number, setMobile_Number] = useState();
  const [Vehicle_Features, setVehicle_Features] = useState();
  const [Emergency_Number, setEmergency_Number] = useState();
  const [Post_Code, setPost_Code] = useState();
  const [Email_Address, setEmail_Address] = useState();
  const [BSB_Number, setBSB_Number] = useState();
  const [Account_Number, setAccount_Number] = useState();
  const [Bank_Name, setBank_Name] = useState();
  const [Full_Name, setFull_Name] = useState();
  const [Sex, setSex] = useState();
  const [ABN, setABN] = useState();
  const [Etag_Number, setEtag_Number] = useState();
  const [Card_Holder, setCard_Holder] = useState();
  const [Driver_License_Back, setDriver_License_Back] = useState();
  const [errmsg, seterrmsg] = useState();
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(1)
  const [enabled, setEnabled] = useState();
  const [msg, setMsg] = useState(null);
  const [showAustralian, setShowAustralian] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptFees, setAcceptFees] = useState(false);
  const [loadingFlag, setloadingFlag] = useState(false);
  const [regErr, setRegErr] = useState();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false)
    navigate('/')
  };

  function onFileUpload(event, fileId) {
    try {
      event.preventDefault();
      const id = fileId;
      const file = event.target.files[0];

      console.log(event)

      // Check file size
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB > 5) {
        seterrmsg({
          ...errmsg,
          [event.target.name]: 'File size exceeds 5MB limit.',
        });

        // Clear the input value
        event.target.value = null;

        // Remove the file from the state if it exists
        const fileIndex = files.findIndex((file) => file.field_id === fileId);
        if (fileIndex !== -1) {
          const updatedFiles = [...files];
          updatedFiles.splice(fileIndex, 1);
          setFiles(updatedFiles);
        }

        return;
      } else {
        seterrmsg({
          ...errmsg,
          [event.target.name]: '',
        });
      }
      // Create an instance of FileReader API
      const fileReader = new FileReader();


      fileReader.onload = () => {
        const base64 = fileReader.result.split(',')[1];

        const fileIndex = files.findIndex((file) => file.field_id === fileId)

        if (fileIndex !== -1) {
          const updatedFiles = [...files]

          updatedFiles[fileIndex] = {
            file: base64,
            field_id: fileId,
            document_id: id,
            fileName: file.name,
          };

          setFiles(updatedFiles)

          seterrmsg({
            ...errmsg,
            [event.target.name]: (<p style={{ color: 'black' }}>
              <span style={{ color: 'green' }}>Selected File: </span>{file.name.slice(0, file.name.lastIndexOf('.')).length > 8 ? `${file.name.slice(0, 8)}...` : file.name.slice(0, file.name.lastIndexOf('.'))}.{file.name.slice(file.name.lastIndexOf('.') + 1)} ✅
            </p>),
          });
        } else {
          setFiles([
            ...files,
            { file: base64, field_id: id, document_id: id, fileName: file.name },
          ]);

          seterrmsg({
            ...errmsg,
            [event.target.name]: (<p style={{ color: 'black' }}>
              <span style={{ color: 'green' }}>Selected File: </span>{file.name.slice(0, file.name.lastIndexOf('.')).length > 8 ? `${file.name.slice(0, 8)}...` : file.name.slice(0, file.name.lastIndexOf('.'))}.{file.name.slice(file.name.lastIndexOf('.') + 1)} ✅
            </p>),
          });
        }
      };

      fileReader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
    }
  }

  // handle submit button for form file_reader.result
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create a new FormData instance
      const formData = new FormData();

      // Append each file to the FormData object
      formData.append("First_Name", First_Name);
      formData.append("Last_Name", Last_Name);
      formData.append("Full_Name", Full_Name);
      formData.append("Username", First_Name + Last_Name);
      formData.append("Fleets_Taxi", Fleets_Taxi);
      formData.append("Operator_Name", Operator_Name);
      formData.append("Operator_Number", Operator_Number);
      formData.append("Vehicle_Features", Vehicle_Features);
      formData.append("Taxi_Made", Taxi_Made);
      formData.append("Vehicles_VIN", Vehicles_VIN);
      formData.append("Vehicles_Model", Vehicles_Model);
      formData.append("Registration_Expiry_Date", Registration_Expiry_Date);
      formData.append("Plate_Number", Plate_Number);
      formData.append("Vehicle_Color", Vehicle_Color);
      formData.append("DOB", DOB);
      formData.append("Street_Address", Street_Address);
      formData.append("Suburb", Suburb);
      formData.append("Mobile_Number", Mobile_Number);
      formData.append("Emergency_Number", Emergency_Number);
      formData.append("Post_Code", Post_Code);
      formData.append("BSB_Number", BSB_Number);
      formData.append("Account_Number", Account_Number);
      formData.append("Bank_Name", Bank_Name);
      formData.append("email", Email_Address);
      formData.append("Sex", Sex);
      formData.append("ABN", ABN);
      formData.append("Etag_Number", Etag_Number);
      formData.append("Card_Holder", Card_Holder);
      // 29
      formData.append("Authority_Card_Back", files.find((item) => item.field_id === 1)?.file || null);
      formData.append("Authority_Card_Front", files.find((item) => item.field_id === 2)?.file || null);
      formData.append("Self_Picture", files.find((item) => item.field_id === 3)?.file || null);
      formData.append("Driver_License_Back", files.find((item) => item.field_id === 4)?.file || null);
      formData.append("Driver_License_Front", files.find((item) => item.field_id === 5)?.file || null);
      formData.append("Police_Check", files.find((item) => item.field_id === 6)?.file || null);
      formData.append("English_Language", files.find((item) => item.field_id === 7)?.file || null);
      formData.append("Driving_History", files.find((item) => item.field_id === 8)?.file || null);
      if (showAustralian === false) {
        formData.append("Vevo_Papers", files.find((item) => item.field_id === 10)?.file || null);
        formData.append("Passport_ID", files.find((item) => item.field_id === 11)?.file || null);
      }
      // 39
      if (showAustralian) {
        formData.append("Aus_Citi_Medi", files.find((item) => item.field_id === 9)?.file || null);
      }
      // 38
      setloadingFlag(true)

      const response = await axios.post(
        "https://taxi2.thecaptaintaxis.com.au/DriverRegistrationAPI/",
        formData
      );

      if (response.status === 200 || response.status === 201) {
        // alert("Driver registered successfully!");
        toast.success("Driver registered successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        handleOpen()
      }

      setloadingFlag(false)
      setMsg(response);

    } catch (err) {
      setloadingFlag(false)
      console.log(err);
      if (err?.response?.status === 400) {
        toast.error(err?.response?.message ? err?.response?.message : "Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setRegErr(err.response.data);
        // seterrmsg(err.response.data);
      }
    }
  }

  useEffect(() => {
    console.log(files);
    const englishFile = files.find((item) => item.field_id === 7)?.file || null;
    let requiredFiles = showAustralian ? 9 : 10;

    if (files.length === requiredFiles || (files.length === requiredFiles - 1 && (englishFile === null || !englishFile))) {
      setShowTerms(true);
    } else {
      setShowTerms(false);
    }
  }, [files, showAustralian]);

  const handleNext = () => {
    const errorField = () => {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };

    const errorDocument = () => {
      toast.error("Please upload required documents.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };

    const checkTermsAndFees = () => {
      if (!acceptTerms || !acceptFees) {
        toast.error("Please accept terms and conditions.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false;
      }
      return true;
    };

    if (step === 1) {
      if (!First_Name || !Last_Name || !DOB || !Street_Address || !Suburb || !Mobile_Number || !Post_Code || !Full_Name || !Sex || !Emergency_Number || !ABN || !Etag_Number || !Email_Address || (enabled === false)) {
        errorField()
      } else {
        setStep(step + 1);
      }
    }

    if (step === 2) {
      if (!Fleets_Taxi || !files.length >= 2 || !Operator_Name || !Operator_Number || !Vehicle_Features || !Taxi_Made || !Vehicles_VIN || !Vehicles_Model || !Registration_Expiry_Date || !Plate_Number || !Vehicle_Color) {
        errorField()
      } else {
        setStep(step + 1)
      }
    }

    if (step === 3) {
      const englishFile = files.find((item) => item.field_id === 7)?.file || null;
      const requiredFiles = showAustralian ? 9 : 10;

      if (files.length === requiredFiles || (files.length === requiredFiles - 1 && englishFile === null)) {
        if (checkTermsAndFees()) {
          setStep(step + 1);
        }
      } else {
        errorDocument();
        return;
      }
    }

    if (step === 4) {
      if (!Bank_Name || !Card_Holder || !BSB_Number || !Account_Number) {
        errorField()
      }
      else {
        setStep(step + 1);
      }
    }
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1)
  };

  const handleMobileNumberChange = (e) => {
    const newMobileNumber = e.target.value;
    setMobile_Number(newMobileNumber);

    const isValidAustralianNumber = /^04\d{8}$/.test(newMobileNumber);

    if (isValidAustralianNumber === false) {
      setEnabled(false)
    } else {
      setEnabled(true)
    }

    seterrmsg({
      ...errmsg,
      Mobile_Number: isValidAustralianNumber === false && 'Invalid Australian mobile number',
    });

    if (regErr?.Mobile_Number?.length >= 1) {
      setRegErr((prevRegErr) => ({
        ...prevRegErr,
        Mobile_Number: [],
      }));
    }
  };

  const handleShowAustralian = (value) => {
    console.log(errmsg)
    if (value) {
      setShowAustralian(true)
      const updatedFiles = files.filter(file => file.field_id !== 10 && file.field_id !== 11);
      setFiles(updatedFiles);
      delete errmsg['Vevo_Papers']
      delete errmsg['Passport_ID']
    } else {
      setShowAustralian(false)
      const updatedFiles = files.filter(file => file.field_id !== 9);
      setFiles(updatedFiles);
      delete errmsg['Aus_Citi_Medi']
    }
  }

  return (
    <>
      <HeaderMenu />
      {loadingFlag && <CustomLoader
        open={loadingFlag}
        stopLoading={setloadingFlag}
      />}

      <ParallaxDiv image="assets/DRIVER-BG1.webp" title="Be part of The Captain Taxis Crew" content="Empower your journey by joining our team of professional drivers, where reliability meets opportunity." attach="fixed" enable={false} />

      <ToastContainer />

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div className="d-flex flex-column gap-4 justify-content-between">
            <div>
              <div className="d-flex gap-2 justify-content-start items-align-center flex-wrap">
                <FcApproval size={35} color="indigo" />
                <h3 style={{ color: 'indigo' }}>Registration Successful</h3>
              </div>
              <div>
                <p>Thank you for registering as a Driver at The Captain Taxis.</p>
                <a className="text-dark" href="/contact_us">For any queries, contact us.</a>
              </div>
            </div>
            <div className="text-end">
              <button onClick={handleClose} className="aboutmorebtn">CLOSE</button>
            </div>
          </div>
        </Box>
      </Modal>

      <div className="main">
        <div className="blur py-5">
          <span className="h1 w-fit m-0 mx-auto text-uppercase" style={{ color: "indigo", width: 'fit-content', fontFamily: 'Poppins', borderBottom: '5px solid #004080' }}>Join as a Driver</span>

          <div className="container rounded mt-5 mb-4 py-5 px-3 px-sm-5 border border-dark" style={{ boxShadow: '0px 0px 5px 0px gainsboro', background: "#c0c0c0" }}>
            <p className="ms-auto text-dark text-end fw-bold mt-0" style={{ fontStyle: 'italic' }}>
              0{step}/04
            </p>

            <p className="text-center mb-4">
              <Typed
                strings={["Please fill in the details and click sign up"]}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
            </p>

            <Fade>
              <form onSubmit={handleSubmit}>
                <p style={{ color: "green" }}>{msg && msg.Username}</p>

                {/* PERSONAL DETAIL */}
                {step === 1 && <div>
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Personal Details</h5>
                    <div className="col-md-3">
                      <label className="m-2">First Name</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="First_Name"
                        placeholder="First Name"
                        value={First_Name}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            Username: [],
                          }));
                          setFirst_Name(e.target.value);
                        }}
                      />
                      <p className="error_message">{errmsg && errmsg.First_Name}</p>
                    </div>
                    <div className="col-md-3">
                      <label className="m-2">Last Name</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Last_Name"
                        placeholder="Last Name"
                        value={Last_Name}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            Username: [],
                          }));
                          setLast_Name(e.target.value);
                        }}
                      />
                      <p className="error_message">{errmsg && errmsg.Last_Name}</p>
                    </div>
                    <div className="col-md-3">
                      <label className="m-2">Date of Birth</label>
                      <input
                        className="input-filed"
                        type="date"
                        name="DOB"
                        placeholder="Date of Birth"
                        value={DOB}
                        onChange={(e) => setDOB(e.target.value)}
                      />
                      <p className="error_message">{errmsg && errmsg.DOB}</p>
                    </div>
                    <div className="col-md-3">
                      <label className="m-2">Mobile Phone</label>
                      <input
                        className="input-filed"
                        type="number"
                        name="Mobile_Number"
                        placeholder="Mobile Phone"
                        maxLength={10}
                        minLength={10}
                        value={Mobile_Number}
                        onChange={handleMobileNumberChange}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Mobile_Number}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-1 mb-1" style={{ textAlign: "left" }}>
                    <div className="col-md-3">
                      <label className="m-2">Email</label>
                      <input
                        className="input-filed"
                        type="email"
                        name="Email_Address"
                        placeholder="Email"
                        value={Email_Address}
                        onChange={(e) => setEmail_Address(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Email_Address}
                      </p>
                    </div>

                    <div className="col-md-3">
                      <label className="m-2">Suburb</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Suburb"
                        placeholder="Suburb"
                        value={Suburb}
                        onChange={(e) => setSuburb(e.target.value)}
                      />
                      <p className="error_message">{errmsg && errmsg.Suburb}</p>
                    </div>

                    <div className="col-md-3">
                      <label className="m-2">Postal Code</label>
                      <input
                        className="input-filed"
                        type="number"
                        maxLength={4}
                        minLength={4}
                        name="Post_Code"
                        placeholder="Postal Code"
                        value={Post_Code}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            Post_Code: [],
                          }));
                          setPost_Code(e.target.value);
                        }}
                      />
                      <p className="error_message">{errmsg && errmsg.Post_Code}</p>
                    </div>

                    <div className="col-md-3">
                      <label className="m-2">Sex</label>
                      <select
                        className="input-filed"
                        name="Sex"
                        required
                        value={Sex && Sex}
                        onChange={(e) => setSex(e.target.value)}
                      >
                        <option value="" disabled selected>Select Sex</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <p className="error_message">
                        {errmsg && errmsg.Sex}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-1 mb-1" style={{ textAlign: "left" }}>
                    <div className="col-md-12">
                      <label className="m-2">Address</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Street_Address"
                        placeholder="Address"
                        value={Street_Address}
                        onChange={(e) => setStreet_Address(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Street_Address}
                      </p>
                    </div>
                  </div>

                  {/* EMERGENCY CONTACT */}
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Emergency Contact</h5>
                    <div className="col-md-6">
                      <label className="m-2">Full Name</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Full_Name"
                        placeholder="Full Name"
                        value={Full_Name}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            Full_Name: [],
                          }));
                          setFull_Name(e.target.value);
                        }}
                      />
                      <p className="error_message">{errmsg && errmsg.Full_Name}</p>
                    </div>
                    <div className="col-md-6">
                      <label className="m-2">Emergency Phone</label>
                      <input
                        className="input-filed"
                        type="number"
                        name="Emergency_Number"
                        placeholder="Emergency Phone"
                        maxLength={10}
                        minLength={10}
                        value={Emergency_Number}
                        onChange={(e) => setEmergency_Number(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Emergency_Number}
                      </p>
                    </div>
                  </div>

                  {/* BUSINESS DETAILS */}
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Business Details</h5>
                    <div className="col-md-6">
                      <label className="m-2">ABN</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="ABN"
                        placeholder="ABN"
                        value={ABN}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            ABN: [],
                          }));
                          setABN(e.target.value);
                        }}
                      />
                      <p className="error_message">{errmsg && errmsg.ABN}</p>
                    </div>
                    <div className="col-md-6">
                      <label className="m-2">Etag Number</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Etag_Number"
                        placeholder="Etag Number"
                        value={Etag_Number}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            Etag_Number: [],
                          }));
                          setEtag_Number(e.target.value);
                        }}
                      />
                      <p className="error_message">{errmsg && errmsg.Etag_Number}</p>
                    </div>
                  </div>
                </div>}


                {/* FLEETS */}
                {step === 2 && <div>
                  {/* FLEETS NETWORK */}
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Fleets (Taxi Network)</h5>
                    <div className="col-md-12">
                      {/* <label className="m-2"></label> */}
                      <input
                        className="input-filed"
                        type="text"
                        name="Fleets_Taxi"
                        placeholder="Fleets"
                        value={Fleets_Taxi}
                        onChange={(e) => setFleets_Taxi(e.target.value)}
                      />
                      <p className="error_message">{errmsg && errmsg.Fleets_Taxi}</p>
                    </div>
                  </div>

                  {/* NETWORK AUTHORITY CARD */}
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Network Authority Card</h5>
                    <div className="col-md-6">
                      <label className="m-2">Authority Card Front</label>
                      <input
                        className="input-filed"
                        type="file"
                        name="Authority_Card_Front"
                        id="1"
                        onChange={(e) => onFileUpload(e, 1)}
                        required
                        autoComplete="off"
                        accept=".png, .jpg, .jpeg"
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Authority_Card_Front}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label className="m-2">Authority Card Back</label>
                      <input
                        className="input-filed"
                        type="file"
                        name="Authority_Card_Back"
                        id="2"
                        onChange={(e) => onFileUpload(e, 2)}
                        required
                        autoComplete="off"
                        accept=".png, .jpg, .jpeg"
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Authority_Card_Back}
                      </p>
                    </div>
                  </div>

                  {/* OPERATOR */}
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Operator</h5>
                    <div className="col-md-6">
                      <label className="m-2">Operator Name</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Operator_Name"
                        placeholder="Operator Name"
                        value={Operator_Name}
                        onChange={(e) => setOperator_Name(e.target.value)}
                      />
                      <p className="error_message">{errmsg && errmsg.Operator_Name}</p>
                    </div>
                    <div className="col-md-6">
                      <label className="m-2">Operator Phone</label>
                      <input
                        className="input-filed"
                        type="number"
                        name="Operator_Number"
                        placeholder="Operator Phone"
                        maxLength={10}
                        minLength={10}
                        value={Operator_Number}
                        onChange={(e) => setOperator_Number(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Operator_Number}
                      </p>
                    </div>
                  </div>

                  {/* FEATURES */}
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Features</h5>
                    <div className="col-md-6">
                      <label className="m-2">Vehicle Features</label>
                      <select
                        className="input-filed"
                        name="Vehicle_Features"
                        required
                        value={Vehicle_Features}
                        onChange={(e) => setVehicle_Features(e.target.value)}
                      >
                        <option value="" disabled selected>Select Features</option>
                        <option value="Sedan Driver">Sedan Driver</option>
                        <option value="SUV Driver 7 Seats">SUV Driver 7 Seats</option>
                        <option value="Maxi Driver 11 Seats">Maxi Driver 11 Seats</option>
                        <option value="NDIS">NDIS</option>
                        <option value="Two Wheelchair Spaces">Two Wheelchair Spaces</option>
                      </select>
                    </div>
                  </div>

                  {/* VEHICLE DETAILS */}
                  <div className="row mt-3 mb-3" style={{ textAlign: "left" }}>
                    <h5 style={{ fontWeight: "bold" }}>Vehicle Details</h5>
                    <div className="col-md-3">
                      <label className="m-2">Taxi Made</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Taxi_Made"
                        placeholder="Taxi Made"
                        value={Taxi_Made}
                        onChange={(e) => setTaxi_Made(e.target.value)}
                      />
                      <p className="error_message">{errmsg && errmsg.Taxi_Made}</p>
                    </div>
                    <div className="col-md-3">
                      <label className="m-2">Vehicle's VIN</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Vehicles_VIN"
                        placeholder="Vehicle's VIN"
                        value={Vehicles_VIN}
                        onChange={(e) => setVehicles_VIN(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Vehicles_VIN}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <label className="m-2">Plate Number</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Plate_Number"
                        placeholder="Plate Number"
                        value={Plate_Number}
                        onChange={(e) => setPlate_Number(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Plate_Number}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <label className="m-2">Vehicle Color</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Vehicle_Color"
                        placeholder="Vehicle Color"
                        value={Vehicle_Color}
                        onChange={(e) => setVehicle_Color(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Vehicle_Color}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <label className="m-2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>Vehicle's Model</label>
                      <input
                        className="input-filed"
                        type="number"
                        name="Vehicles_Model"
                        placeholder="Vehicle's Model"
                        minLength={10}
                        value={Vehicles_Model}
                        onChange={(e) => setVehicles_Model(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Vehicles_Model}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <label className="m-2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>Registration Expiry Date</label>
                      <input
                        className="input-filed"
                        type="text"
                        name="Registration_Expiry_Date"
                        placeholder="Registration Expiry Date"
                        value={Registration_Expiry_Date}
                        onChange={(e) => setRegistration_Expiry_Date(e.target.value)}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Registration_Expiry_Date}
                      </p>
                    </div>
                  </div>
                </div>}

                {/* DOCUMENTS */}
                {step === 3 && <div>
                  <div className="row mt-1 mb-1" style={{ textAlign: "left" }}>
                    <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>Documents </h5>

                    {/* IS AUSTRAILIAN CITIZEN */}
                    <div className="row mt-1 mb-1 text-start">
                      <div className="col-md-6">
                        <p className="mb-0">
                          Are you Australian citizen?
                        </p>
                        <div className="d-flex gap-2">
                          <label>
                            <input
                              type="radio"
                              name="australianCitizen"
                              value="yes"
                              checked={showAustralian}
                              onChange={() => handleShowAustralian(true)}
                            />
                            &nbsp;Yes
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="australianCitizen"
                              value="no"
                              checked={!showAustralian}
                              onChange={() => handleShowAustralian(false)}
                            />
                            &nbsp;No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label className="m-2">Driver Picture</label>
                      <input
                        className="input-filed"
                        type="file"
                        name="Self_Picture"
                        id="3"
                        onChange={(e) => onFileUpload(e, 3)}
                        required
                        accept=".png, .jpg, .jpeg"
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Self_Picture}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label className="m-2">Driver License Back</label>
                      <input
                        className="input-filed"
                        type="file"
                        name="Driver_License_Back"
                        id="4"
                        onChange={(e) => onFileUpload(e, 4)}
                        required
                        autoComplete="off"
                        accept=".png, .jpg, .jpeg"
                      />
                      {Driver_License_Back === "" && (
                        <p className="error_message">
                          {errmsg && errmsg.errmsg.Driver_License_Back}
                        </p>
                      )}
                      <p className="error_message">
                        {errmsg && errmsg.Driver_License_Back}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label className="m-2">Driver License Front</label>
                      <input
                        className="input-filed"
                        type="file"
                        name="Driver_License_Front"
                        id="5"
                        onChange={(e) => onFileUpload(e, 5)}
                        required
                        autoComplete="off"
                        accept=".png, .jpg, .jpeg"
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Driver_License_Front}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-1 mb-1" style={{ textAlign: "left" }}>
                    <div className="col-md-4">
                      <label className="m-2">Police Check</label>
                      <input
                        className="input-filed"
                        type="file"
                        name="Police_Check"
                        id="6"
                        onChange={(e) => onFileUpload(e, 6)}
                        required
                        // value={}
                        autoComplete="off"
                        accept=".png, .jpg, .jpeg"
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Police_Check}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label className="m-2">English Proficiency <span style={{ textTransform: 'uppercase', fontSize: 12 }}>(Optional)</span></label>
                      <input
                        className="input-filed"
                        type="file"
                        name="English_Language"
                        id="7"
                        onChange={(e) => onFileUpload(e, 7)}
                        required
                        autoComplete="off"
                        accept=".png, .jpg, .jpeg"
                      />
                      <p className="error_message">
                        {errmsg && errmsg.English_Language}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label className="m-2">Driving History</label>
                      <input
                        className="input-filed"
                        type="file"
                        name="Driving_History"
                        id="8"
                        onChange={(e) => onFileUpload(e, 8)}
                        required
                        autoComplete="off"
                        accept=".png, .jpg, .jpeg"
                      />
                      <p className="error_message">{errmsg && errmsg.Driving_History}</p>
                    </div>

                    {showAustralian ? (
                      <div className="col-md-4">
                        <label className="m-2">Medicare</label>
                        <input
                          className="input-filed"
                          type="file"
                          name="Aus_Citi_Medi"
                          id="9"
                          onChange={(e) => onFileUpload(e, 9)}
                          required
                          autoComplete="off"
                          accept=".png, .jpg, .jpeg"
                        />
                        <p className="error_message">
                          {errmsg && errmsg.Aus_Citi_Medi}
                        </p>
                      </div>
                    ) : null}
                  </div>

                  {showAustralian ? null : (
                    <div className="row mt-1 mb-1" style={{ textAlign: "left" }}>
                      <div className="col-md-4">
                        <label className="m-2">Vevo Papers</label>
                        <input
                          className="input-filed"
                          type="file"
                          name="Vevo_Papers"
                          id="10"
                          onChange={(e) => onFileUpload(e, 10)}
                          required
                          autoComplete="off"
                          accept=".png, .jpg, .jpeg"
                        />
                        <p className="error_message">
                          {errmsg && errmsg.Vevo_Papers}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label className="m-2">Passport Picture</label>
                        <input
                          className="input-filed"
                          type="file"
                          name="Passport_ID"
                          id="11"
                          onChange={(e) => onFileUpload(e, 11)}
                          required
                          autoComplete="off"
                          accept=".png, .jpg, .jpeg"
                        />
                        <p className="error_message">
                          {errmsg && errmsg.Passport_ID}
                        </p>
                      </div>
                    </div>
                  )}

                  {/* TERMS & CONDITION */}
                  {showTerms && <div className="rounded mt-5 mx-auto">
                    <div className="text-seconday h5 text-start">Terms & Conditions (Important).</div>

                    <Accordion className="text-white rounded mb-3 mx-auto" style={{ boxShadow: '0px 0px 5px 0px gainsboro', background: '#4b0082' }}>
                      <AccordionSummary expandIcon={<KeyboardArrowUpIcon sx={{ color: 'white' }} />}>
                        <span className="m-0 h6 text-start">
                          DRIVER - TAXI SERVICE PROVIDER CONSENT
                        </span>
                      </AccordionSummary>
                      <AccordionDetails className="border-top py-4 border-dark" style={{ background: "#c0c0c0" }}>
                        <p className="text-dark text-start">
                          &#x2022; I consent to the private and confidential Information supplied by me, and the data generated from the operational use of programmes by The Captain Taxis to be retained and /or aggregated for the proper purposes of the verification processes as required by Authorized Governmental Agencies in Australia, but limited to Taxi Industry related purposes.
                          <br />
                          <br />

                          &#x2022; I supply and give consent for the use and recording thereof of Personal Images, Driver’s License, Medicare, Australian Criminal Security Checks and Reports, Passport and Driving Records. I consent also to the recording and use of other Records in the case of not being an Australian Citizen.
                          <br />
                          <br />

                          &#x2022; I consent to The Captain Taxis retaining and accessing data for the purpose of confirming that I am an eligible driver under Point to Point Transport Regulations, and to update DVD records.
                          <br />
                          <br />

                          &#x2022; I understand that The Captain Taxis will be able to check whether
                          <br />
                          <br />

                          &#x2022; I have the relevant driver license to provide passenger services and whether I have any disqualifying offences recorded against my name (criminal charges, serious driving offences and Point to Point safety offences).
                          <br />
                          <br />

                          &#x2022; I consent to all relevant information being supplied to STRIPE.COM for Payment Processing Services subject to the Stripe Connected Account Agreement, collectively the “Stripe Services Agreement.
                          <br />
                          <br />

                          &#x2022; I do not consent to personal information being passed to any other parties for marketing purposes, or for any other use, other than as required by Law in Australia or in any other Countries. The use of Aggregated data by The Captain Taxis is permitted only to the extent that my personal data remains unidentifiable.
                          <br />
                          <br />

                          &#x2022; I herewith confirm that I will supply The Captain Taxis with updates of the status of myself as a Taxi / Driver as may be required by Governmental Regulation, from time to time, and / or as to reflect changes to my current status.
                          <br />
                          <br />

                          &#x2022; I hereby also give consent to the deduction of monies from my share of Taxi Fares to be retained by The Captain Taxis for amounts payable as Government Passenger Services Levy, Booking Fees, Credit Card Transaction Fees, and any charges arising from Fares not processed through The Captain Taxis.
                          <br />
                          <br />

                          &#x2022; I authorize and consent to a percentage of Fares to be paid into The Captain Taxis Account, subject to such nomination and acceptance being recorded from both Driver and The Captain Taxis.
                          <br />
                          <br />

                          &#x2022; I consent to Taxi Fares being paid into an Account nominated by me as a Driver.
                          <br />
                          <br />
                        </p>

                        <span className="text-white h5 text-center">
                          Consent is Given by Acceptance of this Consent Document by the Driver as part of his Registration with The Captain Taxis.
                        </span>
                      </AccordionDetails>
                    </Accordion>

                    <div className="text-start justify-self-start">
                      <label>
                        <input
                          type="checkbox"
                          checked={acceptTerms}
                          onChange={(e) => setAcceptTerms(e.target.checked)}
                        />
                        &nbsp;Accept Terms and Conditions
                      </label>
                    </div>
                    <div className="text-start justify-self-start">
                      <label>
                        <input
                          type="checkbox"
                          checked={acceptFees}
                          onChange={(e) => setAcceptFees(e.target.checked)}
                        />
                        &nbsp;You agree to pay $20 admin fees for the registration.
                      </label>
                    </div>
                  </div>}

                </div>}

                {/* BANK DETAILS */}
                {step === 4 && <div>
                  <div className="row mt-1 mb-1" style={{ textAlign: "left" }}>
                    <h5 style={{ paddingTop: "20px", fontWeight: "bold" }}>Bank Details </h5>

                    <div className="col-md-6">
                      <label className="m-2">Bank Name</label>
                      <select
                        className="input-filed"
                        name="Bank_Name"
                        required
                        value={Bank_Name}
                        onChange={(e) => setBank_Name(e.target.value)}
                      >
                        <option value="" disabled selected>Select Bank Name</option>
                        <option value="AMP Bank">AMP Bank</option>
                        <option value="ANZ">ANZ</option>
                        <option value="Bank Australia">Bank Australia</option>
                        <option value="Bank of Melbourns">Bank of Melbourns</option>
                        <option value="Bendigo Bank">Bendigo Bank</option>
                        <option value="Beyond Bank Australia">Beyond Bank Australia</option>
                        <option value="Commonwealth Bank">Commonwealth Bank</option>
                        <option value="Deefencr Bank">Deefencr Bank</option>
                        <option value="Delphi BAnk">Delphi BAnk</option>
                        <option value="Hume Bank">Hume Bank</option>
                        <option value="IMB Bank">IMB Bank</option>
                        <option value="Macuaire Bank">Macuaire Bank</option>
                        <option value="ME Bank">ME Bank</option>
                        <option value="NAB Bank">NAB Bank</option>
                        <option value="St George Bank">St George Bank</option>
                        <option value="Suncorp Group">Suncorp Group</option>
                        <option value="Teachers Mutual Bank">Teachers Mutual Bank</option>
                        <option value="Victoria Mutual Bank">Victoria Mutual Bank</option>
                        <option value="Westpac Bank">Westpac Bank</option>
                        <option value="HSBC">HSBC</option>
                        <option value="ING">ING</option>
                        <option value="MyState Bank">MyState Bank</option>
                        <option value="Unity Bank">Unity Bank</option>
                        <option value="Auswide Bank">Auswide Bank</option>
                        <option value="Bank First">Bank First</option>
                        <option value="BNK Banking Corp">BNK Banking Corp</option>
                        <option value="Central West Credit Union Limited">Central West Credit Union Limited</option>
                        <option value="Australian Military Bank">Australian Military Bank</option>
                        <option value="Border Bank">Border Bank</option>
                        <option value="Adelaide Bank">Adelaide Bank</option>
                        <option value="Australia and New Zealand Banking Group">Australia and New Zealand Banking Group</option>
                        <option value="Bankwest">Bankwest</option>
                        <option value="BOQ">BOQ</option>

                        {/* Add more options as needed */}
                      </select>
                      <p className="error_message">{errmsg && errmsg.Bank_Name}</p>
                    </div>

                    <div className="col-md-6">
                      <label className="m-2">Card Holder</label>
                      <input
                        className="input-filed"
                        type="text"
                        required
                        name="Card_Holder"
                        placeholder="Card Holder"
                        value={Card_Holder}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            Card_Holder: [],
                          }));
                          setCard_Holder(e.target.value);
                        }}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Card_Holder}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <label className="m-2">BSB Number</label>
                      <input
                        className="input-filed"
                        type="number"
                        name="BSB_Number"
                        placeholder="BSB Number"
                        required
                        value={BSB_Number}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            BSB_Number: [],
                          }));
                          setBSB_Number(e.target.value);
                        }}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.BSB_Number}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <label className="m-2">Account Number</label>
                      <input
                        className="input-filed"
                        type="number"
                        name="Account_Number"
                        placeholder="Account Number"
                        maxLength={10}
                        required
                        value={Account_Number}
                        onChange={(e) => {
                          setRegErr((prevRegErr) => ({
                            ...prevRegErr,
                            Account_Number: [],
                          }));
                          setAccount_Number(e.target.value);
                        }}
                      />
                      <p className="error_message">
                        {errmsg && errmsg.Account_Number}
                      </p>
                    </div>
                  </div>
                </div>}

                {regErr && <div className="text-start mt-4" style={{ color: 'red' }}>
                  <p>{regErr.Username && regErr.Username}</p>
                  <p>{regErr.Last_Name && regErr.Last_Name}</p>
                  <p>{regErr.Street_Address && regErr.Street_Address}</p>
                  <p>{regErr.Suburb && regErr.Suburb}</p>
                  <p>{regErr.Post_Code && regErr.Post_Code}</p>
                  <p>{regErr.Email_Address && regErr.Email_Address}</p>
                  <p>{regErr.Mobile_Number && regErr.Mobile_Number}</p>
                  <p>{regErr.Card_Holder && regErr.Card_Holder}</p>
                  <p>{regErr.BSB_Number && regErr.BSB_Number}</p>
                  <p>{regErr.Account_Number && regErr.Account_Number}</p>
                </div>}

                {step < 4 ?
                  <div className="mt-5 text-end gap-3 row sm-justify-content-between justify-content-center">
                    {step > 1 && <div className="aboutmorebtn1 text-center" onClick={handleBack}>Back</div>}
                    <div className="aboutmorebtn1 text-center" onClick={handleNext}>Next</div>
                  </div>
                  :
                  <div className="mt-5 text-end gap-3 row sm-justify-content-between justify-content-center">
                    {step > 1 && <span className="aboutmorebtn1 text-center" onClick={handleBack}>Back</span>}
                    <button className="aboutmorebtn1" type="submit">
                      Sign up
                    </button>
                  </div>
                }

              </form>
            </Fade>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default JoinasaDriver;
