import React from 'react'
import { MdArrowBackIosNew } from "react-icons/md";
import '../../style/ViewDetails.css'

export const ViewCarDetails = ({ closeDetails, selectedDetails, setSelectedCar }) => {
    const handleSelectService = () => {
        closeDetails(false)
        setSelectedCar(selectedDetails?.id)
    }
    return (
        <>
            <div className='viewCarDetails-main'>
                <div className='back-main' onClick={() => closeDetails(false)}>
                    <div style={{ cursor: 'pointer' }}>
                        <MdArrowBackIosNew />
                        <span style={{ marginLeft: '5px' }}>Back</span>
                    </div>
                </div>

                <div className='details-main'>
                    <div className='details-text'>
                        <h3>{selectedDetails?.text ?? ""}</h3>
                        <p>{selectedDetails?.details ?? ""}</p>
                    </div>
                    <img
                        width={"100%"}
                        src={selectedDetails?.img}
                        alt="Next Available"
                    />
                    <p className='details-text available-car'>
                        {selectedDetails?.info}
                    </p>

                    <p className='fareEstimate'>Fare Estimates*</p>
                    <p className='fare-estimate-text'>
                        Prices given are an estimate only based on kilometres travelled. Actual fare may vary due to traffic conditions, alternate routes, weather and unforeseen circumstances. Estimated fares do not include road tolls, airport fees or public holiday surcharges.
                    </p>
                    <div style={{ paddingBottom: '1rem' }}>
                        <button className="select-service-button" onClick={handleSelectService}>
                            Select This Service
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}