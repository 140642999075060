import React, { useRef, useState, useEffect } from 'react';
import { BsArrowRight } from "react-icons/bs";
import { BiSolidCheckCircle } from "react-icons/bi";

export const AboutBox = () => {
  // const [playVid, setPlayVid] = useState('0')

  // const handlePlay = () => {
  //   setPlayVid('1')
  // }

  const iframeRef = useRef(null);

  const handlePlay = () => {
    if (iframeRef.current) {
      iframeRef.current.src += "&autoplay=1";
    }
  };
  // desktopboxdisplay
  return (
    <div className='container my-5 AboutBoxColormainDiv'>
      <div className='row AboutBoxColorLeftDivMain'>
        <div className='col-12 col-md-6 p-4 about-box-first-con h-md-0'>
          <h1 className='h01 about-order-title mb-4 text-start'>We Provide Trusted <span style={{ color: '#c0c0c0' }}>Cab Service</span> in Australia</h1>
          {/* <iframe
            ref={iframeRef}
            className="mapBoxHeaderVideo"
            src={`https://www.youtube.com/embed/ReqsNYIlPwM?si=PQk_iZ37hQTkxadN&autoplay=${playVid}`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe> */}
          <iframe
            ref={iframeRef}
            className="mapBoxHeaderVideo"
            style={{ zIndex: 999 }}
            src={`https://www.youtube.com/embed/ReqsNYIlPwM?si=PQk_iZ37hQTkxadN`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div className='col-12 col-md-6 about-box-second text-align-left'>
          <h1 className='h01 about-order-title-2'>We Provide Trusted <span style={{ color: '#c0c0c0' }}>Cab<br /> Service</span> in Australia</h1>

          <p className='para AboutBoxColorWhite'>Experience top-notch cab services with The Captain Taxis, your trusted transportation partner in Australia. We pride ourselves on reliability, safety, and exceptional customer service.</p>

          <ul className='listul'>
            <div>
              <BiSolidCheckCircle className='AboutBoxColorWhite mt-1' />
              <li>Count on us for punctual and dependable taxi services that align with your schedule.</li>
            </div>
            <div>
              <BiSolidCheckCircle className='AboutBoxColorWhite mt-1' />
              <li>Your well-being is our priority; our professional drivers and well-maintained fleet ensure a secure journey.</li>
            </div>
            <div>
              <BiSolidCheckCircle className='AboutBoxColorWhite mt-1' />
              <li>From booking to drop-off, our team is committed to providing a seamless and enjoyable ride every time.</li>
            </div>
          </ul>

          <button className='aboutmorebtn mb-4 w-100' onClick={handlePlay} style={{ width: 'auto' }}>Discover more <BsArrowRight /></button>
        </div>
      </div>

      {/* <div className='row mobileboxdisplay pt-5 pb-5 AboutBoxColorLeftDivMain'>
        <div className='col-md-8 mx-auto text-align-left'>
          <h1 className='h01'>We Provide Trusted <span style={{ color: '#c0c0c0' }}>Cab Service</span> in Australia</h1>

          <iframe
            ref={iframeRef}
            className="mapBoxHeaderVideo"
            src={`https://www.youtube.com/embed/ReqsNYIlPwM?si=PQk_iZ37hQTkxadN`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>

          <p className='mt-4 para AboutBoxColorWhite'>Experience top-notch cab services with The Captain Taxis, your trusted transportation partner in Australia. We pride ourselves on reliability, safety, and exceptional customer service.</p>

          <ul className='listul'>
            <div>
              <BiSolidCheckCircle className='AboutBoxColorWhite mt-1' />
              <li>Count on us for punctual and dependable taxi services that align with your schedule.</li>
            </div>
            <div>
              <BiSolidCheckCircle className='AboutBoxColorWhite col-mt-2 col-sm-mt-0' />
              <li>Your well-being is our priority; our professional drivers and well-maintained fleet ensure a secure journey.</li>
            </div>
            <div>
              <BiSolidCheckCircle className='AboutBoxColorWhite col-mt-2 col-sm-mt-0' />
              <li>From booking to drop-off, our team is committed to providing a seamless and enjoyable ride every time.</li>
            </div>
          </ul>

          <button className='aboutmorebtn w-100' onClick={handlePlay}>Discover more <BsArrowRight /></button>
        </div>
      </div> */}
    </div>
  )
}
