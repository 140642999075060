import React from 'react'
import { BsArrowRight, BsAirplaneEnginesFill } from "react-icons/bs";
import { RiTaxiWifiFill } from "react-icons/ri";
import { FaPeopleGroup, FaBusinessTime } from "react-icons/fa6";
import { IoCarSportOutline } from "react-icons/io5";
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { LocalConvenienceStore, ManageAccounts } from '@mui/icons-material';
import { HeaderMenu } from '../Home/HeaderMenu';
import { ParallaxDiv } from '../parallaxDiv/parallaxDiv';
import { Footer } from '../footer/Footer';
import { EstimateDiv } from '../estimateFare/estimateFare';

const VehiclesPage = () => {
    return (
        <>
            <HeaderMenu />
            <ParallaxDiv image="assets/SERVICE-BG-COMPRESS3.webp" title="Explore The Captain Taxis Fleet: Your Ride, Your Choice" content="Explore a diverse fleet, where every journey meets your unique needs." attach="unset" enable={true} />

            <div className='my-5'>
                <div className='service-box-con'>
                    <h1>Our Best Vehicles</h1>
                </div>

                <div className="d-flex flex-wrap justify-content-center gap-0 mt-5">
                    <div className="col-12 vehicle-con-card">
                        <div className='vehicle-img-con sedan-img-con'>
                            <img src="assets/SEDAN-PNG-03.webp" alt="sedan" />
                        </div>
                        <div className='vehicle-desc-con'>
                            <span className='h1'>Standard Sedan</span>
                            <p>Experience the perfect blend of style and comfort with our sedan fleet, offering a smooth and sophisticated ride for your daily travels.</p>
                            <Link to="/book_taxi"><button className="aboutmorebtn1">Book a Taxi <BsArrowRight /></button></Link>
                        </div>
                    </div>
                    <div className="col-12 vehicle-con-card">
                        <div className='vehicle-img-con suv-img-con'>
                            <img className='suv-img' src="assets/SUV-PNG-03.webp" alt="suv" />
                        </div>
                        <div className='vehicle-desc-con'>
                            <span className='h1'>SUV</span>
                            <p>Navigate the city with spacious ease in our SUVs, providing both comfort and versatility for those who prefer a bit more room on the road.</p>
                            <Link to="/book_taxi"><button className="aboutmorebtn1">Book a Taxi <BsArrowRight /></button></Link>
                        </div>
                    </div>
                    <div className="col-12 vehicle-con-card">
                        <div className='vehicle-img-con maxi-img-con'>
                            <img src="assets/MAXI-PNG-03.webp" alt="maxi" />
                        </div>
                        <div className='vehicle-desc-con'>
                            <span className='h1'>Maxi</span>
                            <p>For group excursions or larger parties, our maxi vehicles ensure a spacious and enjoyable journey, making group travel both convenient and enjoyable.</p>
                            <Link to="/book_taxi"><button className="aboutmorebtn1">Book a Taxi <BsArrowRight /></button></Link>
                        </div>
                    </div>
                    <div className="col-12 vehicle-con-card">
                        <div className='vehicle-img-con'>
                            <img src="assets/CHAIR01.webp" alt="wheelchair" />
                        </div>
                        <div className='vehicle-desc-con'>
                            <span className='h1'>Wheelchair-Accessible</span>
                            <p>Our wheelchair-accessible vehicles prioritize inclusivity, providing a comfortable and accessible transportation option for passengers with diverse mobility needs.</p>
                            <Link to="/book_taxi"><button className="aboutmorebtn1">Book a Taxi <BsArrowRight /></button></Link>
                        </div>
                    </div>
                    <div className="col-12 vehicle-con-card">
                        <div className='vehicle-img-con'>
                            <img src="assets/PARCEL-DELIVERY-03.webp" alt="parcel-service" />
                        </div>
                        <div className='vehicle-desc-con'>
                            <span className='h1'>Parcel Service</span>
                            <p>Rely on our efficient parcel delivery service, ensuring your packages reach their destination securely and on time, backed by the reliability of The Captain Taxis.</p>
                            <Link to="/book_taxi"><button className="aboutmorebtn1">Book a Taxi <BsArrowRight /></button></Link>
                        </div>
                    </div>
                </div>
            </div >

            <EstimateDiv />
            <Footer />
        </>
    )
}

export default VehiclesPage;