import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

//const center = { lat: -25.2744, lng: 133.7751 };
const center = { lat: -33.8688, lng: 151.2093 };

export const MapBox = () => {


  return (
    <div className="container mb-5 mt-5">
      <div
        className="row mapBoxHeaderDiv"
      >
        {/* <Fade> */}
        <div className="col-md-6 mt-2 mb-2 p-md-3">
          <div className="mapBoxHeaderVideoPArentDiv">
            <iframe className="mapBoxHeaderVideo" src="https://www.youtube.com/embed/s4kAbTN-6LI?si=-KKv5QZBniYQTMog" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

            {/* <video
                src=""
                alt="videotag"
                type="video/mp4"
                controls
                loop
                preload="none"
                muted
                className="mapBoxHeaderVideo"
              >
                <source src="https://youtu.be/s4kAbTN-6LI?si=wjiuDkkpDpZq49CW" type="video/mp4"/>
              </video> */}
          </div>
        </div>
        <div className="col-md-6 mt-2 mb-2 p-md-3 text-align-left">
          <div>
            <h2
              className="mapBoxHeaderVideoText"
            >
              Welcome to The Captain Taxis
            </h2>
            <h3 className="mapBoxHeaderVideoTaxiTest">
              Reliable and Inclusive: The Captain Taxis - Your Trusted Transport!
            </h3>
            <p
              className="mt-3 mapBoxHeaderVideoParaTest"
            >
              Step into a world of dependable and inclusive transportation with The Captain Taxis! Welcome aboard your trusted transport solution, where reliability meets inclusivity. Count on The Captain Taxis to ensure a smooth and secure journey, catering to all your travel needs with unwavering trustworthiness.
            </p>
            <div className="mapboxbtn">
              <Link to="/join_as_a_driver"><button className="aboutmorebtn1">Join As A Driver <BsArrowRight /></button></Link>
              <Link to="/book_taxi"><button className="aboutmorebtn">Book A Taxi <BsArrowRight /></button></Link>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </div>
    </div>
  );
}
