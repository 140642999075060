import { HeaderMenu } from "../Home/HeaderMenu"
import { Footer } from "../footer/Footer"
import '../../style/additional.css'
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AdditionalLinks = () => {
    return (
        <>
            <HeaderMenu />
            <div className="mb-5 p-0 p-md-5">
                <div>
                    <h1 className="about-title text-dark mx-auto">Additional Links</h1>
                    <p>Resources for any doubt to confirm.</p>
                </div>

                <div className="p-3 links-con p-sm-5 mt-1 text-left" style={{ textAlign: 'left' }}>
                    <div>
                        <h5>1:</h5>
                        <div>
                            <h4>Australia's national competition, consumer (ACCC):</h4>
                            <a target="_blank" href="https://www.accc.gov.au/">https://www.accc.gov.au/</a>
                        </div>
                    </div>

                    <div>
                        <h5>2:</h5>
                        <div>
                            <h4>Transport.NSW:</h4>
                            <a target="_blank" href="https://www.transport.nsw.gov.au/">https://www.transport.nsw.gov.au/</a>
                        </div>
                    </div>

                    <div>
                        <h5>3:</h5>
                        <div>
                            <h4>Point to Point Transport Commissioner:</h4>
                            <a target="_blank" href="https://www.pointtopoint.nsw.gov.au/">https://www.pointtopoint.nsw.gov.au/</a>
                        </div>
                    </div>

                    <div>
                        <h5>4:</h5>
                        <div>
                            <h4>NSW Taxi Council:</h4>
                            <a target="_blank" href="https://www.nswtaxi.org.au/">https://www.nswtaxi.org.au/</a>
                        </div>
                    </div>

                    <div>
                        <h5>5:</h5>
                        <div>
                            <h4>Sydney Airport:</h4>
                            <a target="_blank" href="https://www.sydneyairport.com.au/">https://www.sydneyairport.com.au/</a>
                        </div>
                    </div>

                    <div>
                        <h5>6:</h5>
                        <div>
                            <h4>Australian Taxi Drivers Association (TDA):</h4>
                            <a target="_blank" href="https://www.tda.net.au/">https://www.tda.net.au/</a>
                        </div>
                    </div>

                    {/* <a href="/join_as_a_driver" className="pb-5 text-center" style={{ color: "#4b0082" }}>Join us on the road to a new era of transportation. Captain Taxis – Your Journey, Our Commitment..</a> */}
                </div>
            </div>
            <Footer />
        </>
    )
}
export default AdditionalLinks