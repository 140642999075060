import React from 'react'
import { BsArrowRight, BsAirplaneEnginesFill, BsBoxSeamFill } from "react-icons/bs";
import { RiTaxiWifiFill } from "react-icons/ri";
import { FaPeopleGroup, FaBusinessTime } from "react-icons/fa6";
import { IoCarSportOutline } from "react-icons/io5";
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { LocalConvenienceStore, ManageAccounts, WheelchairPickup } from '@mui/icons-material';
import { MdOutlineCorporateFare, MdPersonAddDisabled } from 'react-icons/md';

export const Servicesbox = () => {

  return (
    <div>
      <div className='container p-5 py-5' style={{ backgroundColor: '#c0c0c0', borderRadius: '15px' }}>
        <div className='service-box-con'>
          <h1 style={{ fontWeight: 900 }}>Our Services</h1>
          <p className='mt-4 mb-0 text-start'>The Captain Taxis is your go-to transportation service offering a range of specialized services tailored to meet your diverse needs. We know how hectic it can be when you're in a hurry to reach your destination. That's why we've chosen skilled drivers who appreciate the importance of being punctual, providing a comfortable ride, and driving responsibly. We take pride in their outstanding professionalism, making your journey with The Captain Taxis reliable and trustworthy. Choose The Captain Taxis for more than just affordable rides – experience the reliability of a family.</p>
          <img src="assets/SERVICE-CAR-BG.webp" className='service-con-img' alt="captain-taxis" />
        </div>

        <div className="d-flex flex-wrap justify-content-center gap-5 text-white text-start mt-5 features-Con">
          <div className="col-12 service-con-card col-md-5">
            <div className="bluebgcircle1 mb-3">
              <BsAirplaneEnginesFill className='mt-2 iconfeatues' />
            </div>
            <h4>Wheels to Wings: Your Airport Shuttle Solution</h4>
            <p>Welcome to our Airport Taxi Service, where convenience and reliability meet to make your airport transportation effortless. We provide prompt pickups from the airport or comfortable rides to your departure gate, 24/7. Our licensed drivers and well-maintained vehicles ensure your safety and comfort. With transparent pricing and easy booking options, we're here to make your journey to or from the airport seamless.Experience hassle-free travel with our trusted Airport Taxi Service.</p>
          </div>
          <div className="col-12 service-con-card col-md-5">
            <div className="bluebgcircle1 mb-3">
              <WheelchairPickup className="mt-2 iconfeatues" />
            </div>
            <h4>NDIS Support Service</h4>
            <p>Our NDIS Taxi Service is dedicated to providing accessible and supportive transportation for individuals with disabilities. We understand the unique needs of our passengers and are committed to ensuring safe, comfortable, and reliable rides. Our specially equipped vehicles and trained drivers ensure that every journey is a stress-free experience, offering independence and convenience to NDIS participants. Count on us for inclusive transportation that puts your needs first.</p>
          </div>
          <div className="col-12 service-con-card col-md-5">
            <div className="bluebgcircle1 mb-3">
              <BsBoxSeamFill className="mt-2 iconservice" />
            </div>
            <h4>Parcel Service</h4>
            <p>Introducing our Taxi Parcel Delivery Service, your solution for swift and secure package transportation. We leverage our extensive taxi service to swiftly deliver your parcels, ensuring they reach their destination on time. With our reliable drivers and real-time tracking, you can trust us with your valuable deliveries. Say goodbye to delays and logistics headaches; choose our Taxi Parcel Delivery Service for efficient and dependable courier solutions.</p>
          </div>
          <div className="col-12 service-con-card col-md-5">
            <div className="bluebgcircle1 mb-3">
              <FaPeopleGroup className='mt-2 iconservice' />
            </div>
            <h4>Events & Weddings Service</h4>
            <p>Make your special events and weddings truly exceptional with our premier taxi service. We understand the importance of every detail on your big day. Our fleet of elegant and well-maintained vehicles, driven by professional chauffeurs, ensures a stylish and punctual arrival for you and your guests. From bridal party transportation to guest shuttles, our service adds a touch of sophistication and convenience to your event. Trust us to make your moments even more memorable with our impeccable service, leaving you free to focus on celebrating.</p>
          </div>
          <div className="col-12 service-con-card col-md-5">
            <div className="bluebgcircle1 mb-3">
              <MdOutlineCorporateFare className='mt-2 iconfeatues' />
            </div>
            <h4>Corporate Travel Service</h4>
            <p>Meet our top-notch taxi service made just for busy professionals like finance experts, CEOs, and company directors. We know your job is super important, so our service is designed to fit your busy schedule. Our cars are in great shape, and our drivers are experienced, making sure you get to your destination on time and in comfort. Make your work trips easier with our handy corporate accounts and priority service. Count on us to be your reliable travel partner, helping you do your job well</p>
          </div>
          <div className="col-12 service-con-card col-md-5">
            <div className="bluebgcircle1 mb-3">
              <IoCarSportOutline className='mt-2 iconservice' />
            </div>
            <h4>Sydney Taxi Tourist Tour</h4>
            <p>Discover the beauty and excitement of Sydney with our exclusive Sydney Tourist Taxi Service.
              Whether you're a visitor or a local looking to explore, our knowledgeable drivers will take you on a memorable journey through iconic landmarks like the Sydney Opera House, Harbour Bridge, Bondi Beach, and more.
              Our comfortable vehicles ensure a leisurely experience as you soak in the city's rich culture and breathtaking views.
              Let us be your guide to the best of Sydney, offering convenience and personalized tours that make every moment unforgettable.
            </p>
          </div>
        </div>
      </div>
    </div >
  )
}