import { RiTaxiWifiFill, RiTimer2Fill } from "react-icons/ri";
import { FaPeopleGroup, FaUserNurse } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { FaCarOn } from "react-icons/fa6";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import Fade from 'react-reveal/Fade';
import { AccessTime, AccountBalance, BookOnline, DriveEtaSharp, Weekend } from "@mui/icons-material";

export const Features = () => {
    return (
        <div className='container-fluid featuresbgimage mt-5'>
            <div className="blur">
                <div className="container pt-5 pb-5" style={{ background: "#c0c0c0", borderRadius: '15px' }}>
                    <div className="text-dark px-3">
                        <h3 className="howTitle" style={{ color: '#000' }}>Why Choose The Captain Taxis</h3>
                        <p className="w-md-75 mt-4 mx-auto text-start" style={{ color: '#444444', width: '75%' }}>Choose The Captain Taxis for a transportation experience that goes beyond the ordinary. Our handpicked team of experienced drivers ensures you reach your destination with a perfect blend of punctuality, comfort, and responsible driving. We take pride in our exceptional professional behavior, making your journey not just affordable but also reliable, akin to a trusted family. With specialized services such as airport transfers, NDIS support, corporate commutes, event transportation, tours, and reliable parcel delivery, The Captain Taxis caters to your diverse needs. Trust us for a seamless and enjoyable ride, where your satisfaction is our top priority.</p>
                    </div>


                    <div className="d-flex flex-wrap justify-content-center gap-5 text-dark text-start mt-5 p-4 p-sm-0 features-Con">
                        <div className="col-12 service-con-card col-md-5">
                            <div className="bluebgcircle1 mb-3">
                                <BookOnline className="iconfeatues" />
                            </div>
                            <h3>Secure Booking with All Credit Cards:</h3>
                            <p>Enjoy the convenience of booking with any major credit card, ensuring a seamless and secure transaction process when you choose The Captain Taxis for your transportation needs.</p>
                        </div>
                        <div className="col-12 service-con-card col-md-5">
                            <div className="bluebgcircle1 mb-3">
                                <DriveEtaSharp className="iconfeatues" />
                            </div>
                            <h3>Professional Drivers</h3>
                            <p>Experience the difference with our team of professional drivers, carefully selected for their expertise, reliability, and commitment to providing a top-notch transportation service. Your journey with The Captain Taxis is in safe and capable hands.</p>
                        </div>
                        <div className="col-12 service-con-card col-md-5">
                            <div className="bluebgcircle1 mb-3">
                                <AccessTime className="iconfeatues" />
                            </div>
                            <h3>24/7 operations</h3>
                            <p>Whether it's an early morning flight or a late-night event, The Captain Taxis operates round the clock to meet your transportation requirements at any hour. Count on us for reliable and timely service whenever you need it.</p>
                        </div>
                        <div className="col-12 service-con-card col-md-5">
                            <div className="bluebgcircle1 mb-3">
                                <AccountBalance className="iconfeatues" />
                            </div>
                            <h3>Government regulated</h3>
                            <p>Rest assured knowing that The Captain Taxis operates in compliance with government regulations, ensuring a safe and lawful transportation experience. Your well-being is our priority, and we adhere to the highest standards set by regulatory authorities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}