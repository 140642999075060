import React,{useState} from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function WarningSnackBar({warningObj,setwarningObj}) {

  // const [openFlag,setopenFlag]=useState(open)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setwarningObj({...warningObj , open :false})
    // setopenFlag(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={warningObj.open} autoHideDuration={3000} onClose={handleClose}  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert severity={warningObj.type} sx={{ width: '100%' }} onClose={handleClose}>
          {warningObj.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}