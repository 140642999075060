import { useLocation } from 'react-router-dom';
import BookTap from './BookTap';
import { HeaderMenu } from "../Home/HeaderMenu";


const BookTaxi = () => {
  const { state } = useLocation();

  return (
    <>
      <HeaderMenu />

      <div className="">
        <BookTap state={state} />
      </div>
    </>
  )
}
export default BookTaxi


